<template>
  <div class="report">
    <year-warning-bar v-if="hasAdministratorReportPermission" />
    <!-- <alert-info :text-alert="reportTitle" /> -->
    <div>
      <div :class="$style.reportHeader">
        <report-options
          :show-chosen-school-picker="false"
          :hide-school-logo="true"
          :report-key="reportKey">
        </report-options>
      </div>
      <paywall
        :permitted="hasAdministratorReportPermission"
        restricted>
        <div
          v-if="chosenSchoolIds.length > 0"
          :class="$style.reportContainer">
          <administrator-report-dashboard
            :dashboard="dashboard"
            :loading.sync="loading"
          />
        </div>
      </paywall>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import AuthMixin from '../../mixins/auth';
import ReportOptions from '../../components/report-options';
import Paywall from '../../components/paywall.vue';
import AdministratorReportDashboard from '../administrator/administrator-dashboard';
import YearWarningBar from '../../components/utils/year-warning-bar.vue';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'AdmininistratorReportView',
  components: {
    ReportOptions,
    AdministratorReportDashboard,
    Paywall,
    YearWarningBar,
    AlertInfo,
  },
  mixins: [PermissionMixin, AuthMixin],
  data() {
    return {
      dashboard: true,
      loading: 0,
      reportKey: 'administrator_report_school_results',
      reportTitle: 'En esta ficha encontrarás los resultados de tus colegios. Haz click en un colegio para ver detalles.',
      hideAlert: false,
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolIds']),
  },
};

</script>

<style lang="scss" module>
  .report-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    font-size: 28px;
    font-weight: 200;
  }

  .item-content {
      width: 25%;
  }

</style>
