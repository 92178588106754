<template>
  <list-template
    :change-page-callback="changePageCallback"
    :column-keys="columnKeys"
    :sticky-top-offset="stickyTopOffset"
  />
</template>

<script>
import ListTemplate from './list-template.vue';

export default {
  name: 'ListView',
  components: {
    ListTemplate,
  },
  props: {
    stickyTopOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      columnKeys: [
        'full_name', 'formatted_run', 'group_short_name', 'retired', 'preferential', 'priority',
        'pie_boolean', 'repetition', 'repetition_risk_boolean', 'chronic_absenteeism', 'historical_repetition_boolean',
        'formatted_years_since_enrollment', 'negativos', 'neutros', 'positivos'
      ],
    };
  },
  methods: {
    changePageCallback() {
      this.$parent.$refs.main.scrollTop = 185;
    },
  },
};
</script>

