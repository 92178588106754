<template>
  <div>
    <div v-if="loading">
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
    </div>
    <div
      v-else-if="values.length > 0 && groups.length > 0"
      :class="$style.schoolFilterTable">
      <div v-if="dashboard">
        <div :class="$style.dashboardElementsAggregateBox">
          <aggregate-box
            :value="schoolStudentsCount"
            :progress="100"
            text="estudiantes"
          />
          <aggregate-box
            v-if="schoolHasRepetition"
            :value="schoolRepetitionCount"
            :progress="(schoolRepetitionCount / (schoolStudentsCount || 1)) * 100"
            :color="REPETITION_COLOR"
            text="repitentes"
          />
          <aggregate-box
            v-else-if="repetitionRiskPresent"
            :value="schoolRepetitionRiskCount"
            :progress="(schoolRepetitionRiskCount/ (schoolStudentsCount || 1)) * 100"
            :color="REPETITION_COLOR"
            :info-tooltip="repetitionRiskDecree67Tooltip"
            :text="shortRepetitionRiskLabel.toLowerCase()"
          />
          <aggregate-box
            v-if="!useOnSiteAttendances"
            :value="schoolChronicAbsenteeismResult"
            :progress="schoolChronicAbsenteeismResult"
            :color="CHRONIC_COLOR"
            format="percentageWithOneDecimal"
            text="ausentes crónicos"
          />
          <aggregate-box
            :value="attendanceAggregateBoxValue"
            :progress="attendanceAggregateBoxValue"
            :color="ATTENDANCE_COLOR"
            :info-tooltip="attendanceAggregateBoxInfoTooltip"
            :format="attendanceAggregateBoxFormat"
            :text="attendanceAggregateBoxText"
          />
        </div>
        <div :class="$style.dashboardElementsIndicatorBox">
          <indicator-box
            v-for="indicator in indicators"
            :key="indicator.name"
            :color="indicator.color"
            :objects="groupsAsObject"
            :values="indicator.values"
            :ordered-objects="groupsAsRows.map(group => group.id)"
            :title="indicator.name"
            :max-value="indicator.maxValue"
            :reverse-sort="indicator.reverseSort"
            :has-total="indicator.hasTotal"
            :aggr-value="indicator.aggrValue"
            :aggr-format="indicator.aggrFormat"
            :info-tooltip="indicator.infoTooltip"
            :legends="indicator.legends"
            :show-complement="indicator.showComplement"
            :interpolate-colors="indicator.interpolateColors"
            :tooltip-class="$style.infoTooltip"
            :pro="indicator.pro"
            :show-first-value="indicator.showFirstValue"
            :sort-func="indicator.sortFunc"
            object-type="group"
          />
          <div class="item-content" v-for="(card, index) in cardsData" :key="index">
            <custom-card
              :title="card.title"
              :colors="card.colors"
              :schools="card.colegios"/>
          </div>
        </div>
        
      </div>
      <div
        v-else
        :class="$style.table">
        <rdr-table
          :columns="columns"
          :data="rows"
          :click="handleClick"
          :custom-class="$style.schoolReportTable"
          :extended-header-class="$style.schoolReportHeaderCell"
          :extended-cell-class="$style.schoolReportCell"
          :hidden-columns-count="hiddenColumnsCount"
          :sticky-top-offset="isCurrentSchoolYearChosen || isMobile ? 0 : 40"
        />
      </div>
    </div>
    <div
      v-else
      :class="$style.schoolFilterTableEmpty">
      <img src="/noResultados.svg">
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { uniqBy } from 'lodash';
import FilterTable from '../../components/filter-table';
import PermissionMixin from '../../mixins/permission';
import AuthMixin from '../../mixins/auth';
import Decree67Mixin from '../../mixins/decree-67';
import DisplayMixin from '../../mixins/display';
import SchoolsApi from '../../api/schools';
import AggregateBox from '../../components/dashboard/aggregate-box';
import IndicatorBox from '../../components/dashboard/indicator-box';
import {
  REPETITION_COLOR, ATTENDANCE_COLOR, CHRONIC_COLOR, AVERAGE_COLOR, RED_COLOR, FEMALE_COLOR,
  PIE_COLOR, PREFERENTIAL_COLOR, PRIORITY_COLOR, UNDERPERFORMING_COLOR, LOW_READING_QUALITY,
  MALE_COLOR, DARK_GRAY_COLOR, PIE_ADDITIONAL_COLOR, SEMAPHORE_COLORS, SURVEY_COLORS,
  PLANNED_CLASSES_COLOR, LEARNING_OBJECTIVE_COVERAGE_COLOR, PULSO_COLOR,
} from '../../utils/style-variables';
import { normalize } from '../../utils/interpolate-function';
import schoolYear from '../../utils/years';
import { groupBy } from '../../utils/methods';
import customCard from '../administrator-convivencia';

const HIDDEN_COLUMNS_COUNT = 3;

export default {
  name: 'SchoolReportDashboard',
  components: {
    FilterTable,
    AggregateBox,
    IndicatorBox,
    customCard,
  },
  mixins: [PermissionMixin, AuthMixin, Decree67Mixin, DisplayMixin],
  props: {
    dashboard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      groupResults: [],
      filters: [],
      colors: ['#e06666', '#fffdcc', '#93c47d'],
      cardsData: [
      {
  title: "Conducta",
  colors: ["#5AAC27", "#FFCF54", "#FF4F56"],
  colegios: [
      { name: "PK°A", positivos: 450, negativos: 80, neutros: 200, value: 450 + 80 + 200 },
      { name: "K°B", positivos: 520, negativos: 90, neutros: 240, value: 520 + 90 + 240 },
      { name: "1°B", positivos: 610, negativos: 120, neutros: 300, value: 610 + 120 + 300 },
      { name: "7°A", positivos: 720, negativos: 150, neutros: 320, value: 720 + 150 + 320 },
      { name: "2°A", positivos: 590, negativos: 110, neutros: 280, value: 590 + 110 + 280 },
      { name: "4°B", positivos: 640, negativos: 140, neutros: 310, value: 640 + 140 + 310 },
      { name: "4°A", positivos: 670, negativos: 130, neutros: 290, value: 670 + 130 + 290 },
      { name: "3°B", positivos: 580, negativos: 100, neutros: 260, value: 580 + 100 + 260 },
      { name: "5°A", positivos: 700, negativos: 160, neutros: 330, value: 700 + 160 + 330 },
      { name: "6°B", positivos: 740, negativos: 170, neutros: 350, value: 740 + 170 + 350 },
      { name: "8°A", positivos: 780, negativos: 190, neutros: 370, value: 780 + 190 + 370 },
      { name: "3°A", positivos: 560, negativos: 95, neutros: 250, value: 560 + 95 + 250 },
      { name: "5°B", positivos: 690, negativos: 155, neutros: 320, value: 690 + 155 + 320 },
      { name: "6°A", positivos: 720, negativos: 165, neutros: 340, value: 720 + 165 + 340 },
      { name: "8°B", positivos: 790, negativos: 200, neutros: 380, value: 790 + 200 + 380 },
    ],
  }   
      ],
      error: false,
      proGradesMessage: 'Con el plan Académico de RadarEscolar podrías ver acá el promedio de calificaciones actual de este curso',
      aptusExplanationMessage: 'Importante: El logro normalizado de las evaluaciones 2020 de Aptus se realizó con una referencia de colegios distinta a SIP red de colegios.',
      REPETITION_COLOR,
      ATTENDANCE_COLOR,
      CHRONIC_COLOR,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenSchoolId', 'chosenYear']),
    ...mapState('options', { allGroups: 'groups' }),
    ...mapGetters('results', ['results', 'resultsIndicators']),
    localIndicators() {
      return [
        { key: 'promedio-anual-grupo', id: 1, label: 'Promedio de calificaciones', attribute: 'grade_average' },
        { key: 'porcentaje-asistencia', id: 2, label: 'Promedio de asistencia', attribute: 'attendance_average' },
        { key: 'numero-alumnos-grupo', id: 3, label: 'Número de estudiantes', attribute: 'student_count' },
        { key: 'numero-riesgo-repitencia-grupo', id: 4, label: 'Estudiantes en posible repitencia', attribute: 'repetition_risk_count' },
        { key: 'porcentaje-inasistencia-cronica-acumulada', id: 5, label: '% Ausentismo crónico', attribute: 'chronic_absenteeism_average' },
        { key: 'numero-alumnos-pie-grupo', id: 6, label: 'Estudiantes PIE', attribute: 'pie_count' },
        { key: 'numero-alumnos-preferentes-grupo', id: 7, label: 'Estudiantes preferentes', attribute: 'preferential_count' },
        { key: 'numero-alumnos-preferenciales-grupo', id: 8, label: 'Estudiantes prioritarios', attribute: 'priority_count' },
        { key: 'numero-alumnos-bajo-desempeño-emn-aptus', id: 9, label: 'Número de Estudiantes con bajo desempeño EMN Aptus', attribute: 'emn_underperforming_percentage' },
        { key: 'numero-alumnos-bajo-desempeño-pdn-aptus', id: 10, label: 'Número de Estudiantes con bajo desempeño PDN Aptus', attribute: 'pdn_underperforming_percentage' },
        { key: 'numero-de-alumnos-bajo-esperado-calidad-lectora-por-grupo', id: 11, label: 'Número de Estudiantes bajo nivel esperado en calidad lectura', attribute: 'low_reading_quality_percentage' },
        { key: 'numero-alumnos-bajo-desempeño-pdd-aptus', id: 12, label: 'Número de estudiantes con bajo desempeño PDD Aptus', attribute: 'pdd_underperforming_percentage' },
        { key: 'numero-alumnos-bajo-desempeño-mda-aptus', id: 13, label: 'Número de estudiantes con bajo desempeño MDA Aptus', attribute: 'mda_underperforming_percentage' },
        { key: 'numero-de-inasistencias-cronicas-acumuladas', id: 14, label: 'Número estudiantes con ausentismo crónico', attribute: 'chronic_absenteeism_data' },
        { key: 'numero-alumnos-repitentes-grupo', id: 15, label: 'Estudiantes repitentes', attribute: 'repetition_count' },
        { key: 'porcentaje-mujeres', id: 16, label: 'Hombres y mujeres', attribute: 'female_percentage' },
        { key: 'porcentaje-hombres', id: 17, label: 'Hombres y mujeres', attribute: 'male_percentage' },
        { key: 'cantidad-mujeres', id: 18, label: 'Hombres y mujeres', attribute: 'female_count' },
        { key: 'cantidad-hombres', id: 19, label: 'Hombres y mujeres', attribute: 'male_count' },
        { key: 'pie-adicionales', id: 20, label: 'PIE Adicionales', attribute: 'pie_additional' },
        { key: 'evaluaciones-pie', id: 21, label: 'Evaluaciones PIE', attribute: 'pie_evaluations' },
        { key: 'numero-alumnos-repitentes-historico', id: 22, label: 'Estudiantes repitentes', attribute: 'historical_repetition_count' },
        { key: 'porcentaje-clases-planificadas-poptimize', id: 23, label: 'Porcentaje de clases planificadas', attribute: 'poptimize_planned_classes_percentage' },
        { key: 'porcentaje-oas-cubiertos-poptimize', id: 24, label: 'Porcentaje de OAs cubiertos', attribute: 'poptimize_learning_objective_coverage_percentage' },
        { key: 'promedio-asistencia-presencial', id: 25, label: 'Promedio de asistencia presencial', attribute: 'on_site_attendance_average' },
        { key: 'numero-de-alumnos-total-calidad-lectora-por-grupo', id: 26, label: 'Número de estudiantes total calidad lectora', attribute: 'reading_quality_total' },
        { key: 'alertas-pulso-escolar', id: 27, label: 'Alertas de Pulso Escolar', attribute: 'pulso_alerts' },
        ...(this.surveyAlertsLocalIndicators),
      ];
    },
    surveyAlertsLocalIndicators() {
      const baseAlertIndex = 1000;
      return this.surveyAlerts.map((alert, index) => (
        { ...alert, id: baseAlertIndex + index }
      ));
    },
    groups() {
      const groupIds = new Set(this.groupResults.map(result => result.group_id));
      return this.allGroups.filter(g => groupIds.has(g.id));
    },
    groupsAsRows() {
      return this.groups
        .map(group => (
          {
            id: group.id,
            value: group.short_name,
            key: group.key,
            link: {
              name: 'groupReport',
              params: { school: this.chosenSchoolId, year: this.chosenYear },
            },
            handlePress: () => this.setChosenGroup(group.key),
          }
        ));
    },
    groupsAsObject() {
      return this.groupsAsRows.reduce((obj, item) => {
        obj[item.id] = { name: item.value, link: item.link, handlePress: item.handlePress };

        return obj;
      }, {});
    },
    groupInfo() {
      return this.groups.map(group => ({
        groupId: group.id,
        indicatorId: -1,
        value: group.short_name,
        class: this.$style.centeredValue,
      }));
    },
    values() {
      const values = this.groupResults.map(groupData =>
        this.localIndicators.map(indicator => {
          if (this.invalidResult(indicator.key, groupData)) {
            return {};
          }
          return this.resultHash(indicator.key, groupData);
        }),
      );
      return [].concat(...values);
    },
    valuesPlusGroup() {
      return this.values
        .concat(this.groupInfo);
    },
    groupInfoAsColumns() {
      return [
        { id: -1, label: 'Curso', notCompact: true, sortFunc: (a, b) => a.localeCompare(b), key: 'group_id' },
      ];
    },
    showRepetitionRisk() {
      return !this.schoolHasRepetition && this.repetitionRiskPresent;
    },
    useOnSiteAttendances() {
      return !this.hasAttendanceValues;
    },
    // eslint-disable-next-line complexity
    columns() {
      return [
        { label: 'Curso', key: 'group', sortKey: 'groupOrderKey', textAlign: 'left', sortFunc: (a, b) => a.localeCompare(b), sticky: true },
        {
          label: 'Promedio general', key: 'grade_average_value', format: 'numberWithOneDecimal', type: 'data-box', sortFunc: (a, b) => a - b, maxValue: 7, minValue: 4,
          ...(this.hasGradesPermission ? { colors: this.colors, norm: 'grade_average_value' } : { colors: ['#fff', '#fff'], pro: this.proGradesMessage }),
        },
        ...(this.useOnSiteAttendances ?
          [{
            label: 'Días asistencia presencial', key: 'on_site_attendance_average', format: 'numberWithOneDecimal', type: 'data-box', headerHover: this.onSiteAttendanceTooltip,
            sortFunc: (a, b) => a - b, colors: ['#FFF', ATTENDANCE_COLOR], norm: 'on_site_attendance_average_norm', ignoreIfEmpty: true,
          }] :
          [
            {
              label: 'Promedio asistencia', key: 'attendance', format: 'percentage', type: 'data-box',
              sortFunc: (a, b) => a - b, colors: this.colors, norm: 'attendance_norm_value',
            },
            {
              label: 'Ausentismo crónico', key: 'chronic_absenteeism_count', format: 'round', type: 'data-box',
              sortFunc: (a, b) => a - b, colors: ['#FFF', CHRONIC_COLOR], norm: 'chronic_absenteeism_count_norm',
            },
          ]
        ),
        ...(this.surveyAlertsWithValues.length ? this.surveyAlertsWithValues : []),
        ...(this.showPulsoIndicatorBox ? [{
          label: 'Alertas de Pulso Escolar', key: 'pulso_alerts', format: 'percentage', type: 'data-box', sortFunc: (a, b) => a - b, colors: [PULSO_COLOR],
        }] : []),
        { label: 'Número estudiantes', key: 'student_count', sortFunc: (a, b) => a - b },
        ...(this.schoolHasRepetition ? [{ label: 'Repitentes', key: 'repetition_count', format: 'number', type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', RED_COLOR], norm: 'repetition_count_norm' }] : []),
        ...(this.showRepetitionRisk ? [{ label: this.shortRepetitionRiskLabel, key: 'repetition_risk_count', format: 'number', type: 'data-box', headerHover: this.repetitionRiskDecree67Tooltip, sortFunc: (a, b) => a - b, colors: ['#FFF', REPETITION_COLOR], norm: 'repetition_risk_count_norm' }] : []),
        
        // ...(this.emnUnderperformingCount ?
        //   [{
        //     label: 'EMN Aptus bajo desempeño', key: 'emn_underperforming_count',
        //     sortFunc: (a, b) => a - b, format: 'number',
        //     type: 'data-box', colors: ['#FFF', UNDERPERFORMING_COLOR],
        //     norm: 'emn_underperforming_count_norm',
        //     headerHover: this.chosenYear === 2020 ? this.aptusExplanationMessage : 'EMN Aptus bajo desempeño', // eslint-disable-line no-magic-numbers
        //   }] : []),
        // ...(this.pdnUnderperformingCount || this.userPermissions.aptus_client ?
        //   [{
        //     label: 'PDN Aptus bajo desempeño', key: 'pdn_underperforming_count',
        //     sortFunc: (a, b) => a - b, format: 'number',
        //     type: 'data-box', colors: ['#FFF', UNDERPERFORMING_COLOR],
        //     norm: 'pdn_underperforming_count_norm',
        //     headerHover: this.chosenYear === 2020 ? this.aptusExplanationMessage : 'PDN Aptus bajo desempeño', // eslint-disable-line no-magic-numbers
        //     warning: true,
        //     infoWarning: 'Si aplicas PDN Aptus, podrás ver tus resultados aquí',
        //     warningFunction: () => this.showPdnTeaser,
        //   }] : []),
        // ...(this.pddUnderperformingCount ?
        //   [{
        //     label: 'PDD Aptus bajo desempeño', key: 'pdd_underperforming_count',
        //     sortFunc: (a, b) => a - b, format: 'number',
        //     type: 'data-box', colors: ['#FFF', UNDERPERFORMING_COLOR],
        //     norm: 'pdd_underperforming_count_norm',
        //     headerHover: this.chosenYear === 2020 ? this.aptusExplanationMessage : 'PDD Aptus bajo desempeño', // eslint-disable-line no-magic-numbers
        //   }] : []),
        // ...(this.mdaUnderperformingCount ?
        //   [{
        //     label: 'MDA Aptus bajo desempeño', key: 'mda_underperforming_count',
        //     sortFunc: (a, b) => a - b, format: 'number',
        //     type: 'data-box', colors: ['#FFF', UNDERPERFORMING_COLOR],
        //     norm: 'mda_underperforming_count_norm',
        //     headerHover: this.chosenYear === 2020 ? this.aptusExplanationMessage : 'MDA Aptus bajo desempeño', // eslint-disable-line no-magic-numbers
        //   }] : []),

        ...(this.lowReadingQualityCount ?
          [{
            label: 'C. Lectora bajo desempeño', key: 'low_reading_quality_count',
            sortFunc: (a, b) => a - b, format: 'number',
            type: 'data-box', colors: ['#FFF', LOW_READING_QUALITY],
            norm: 'low_reading_quality_count_norm',
          }] : []),
        ...(this.historicalRepetitionValues ?
          [{
            label: 'Repitentes históricos', key: 'historical_repetition_count',
            type: 'data-box', format: 'number',
            sortFunc: (a, b) => a - b, colors: ['#FFF', RED_COLOR],
            norm: 'historical_repetition_count_norm',
          }] : []),
        { label: 'Estudiantes PIE', key: 'pie_count', format: 'number', type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', PIE_COLOR], norm: 'pie_count_norm', ignoreIfEmpty: true },
        ...(this.hasAdditionalPieValues ?
          [{
            label: 'PIE Adicionales', key: 'pie_additional_value', format: 'number',
            type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', PIE_ADDITIONAL_COLOR],
            norm: 'pie_additional_norm',
          }] : []),
        { label: 'Estudiantes prioritarios', key: 'priority_count', format: 'number', type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', PRIORITY_COLOR], norm: 'priority_count_norm', ignoreIfEmpty: true },
        { label: 'Estudiantes preferentes', key: 'preferential_count', format: 'number', type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', PREFERENTIAL_COLOR], norm: 'preferential_count_norm', ignoreIfEmpty: true },
        ...(this.hasPoptimizePlannedClassesValues ?
          [{
            label: 'Porcentaje clases planificadas', key: 'poptimize_planned_classes_percentage', format: 'percentage', type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', PLANNED_CLASSES_COLOR], norm: 'poptimize_planned_classes_percentage_norm', ignoreIfEmpty: true, headerHover: this.poptimizePlannedClassesTooltip,
          }] : []),
        ...(this.hasPoptimizeLearningObjectiveCoverageValues ?
          [{
            label: 'Porcentaje OAs cubiertos', key: 'poptimize_learning_objective_coverage_percentage', format: 'percentage', type: 'data-box', sortFunc: (a, b) => a - b, colors: ['#FFF', LEARNING_OBJECTIVE_COVERAGE_COLOR], norm: 'poptimize_learning_objective_coverage_percentage_norm', ignoreIfEmpty: true, headerHover: this.poptimizeLearningObjectiveCoverageTooltip,
          }] : []),
      ].filter(column => !column.ignoreIfEmpty || this.hasValues(column, this.groupResults));
    },
    hiddenColumnsCount() {
      return this.emnUnderperformingCount ||
        this.pdnUnderperformingCount ||
        this.pddUnderperformingCount ||
        this.lowReadingQualityCount ?
        HIDDEN_COLUMNS_COUNT :
        0;
    },
    rows() {
      const outputRows = this.groupResults.map(this.resultToRow);

      return outputRows.sort((a, b) =>
        a.groupOrderKey.localeCompare(b.groupOrderKey),
      );
    },

    options() {
      return {
        schoolId: this.chosenSchoolId,
        year: this.chosenYear,
      };
    },
    surveyAlerts() {
      const alerts = this.groupResults.flatMap(groupData =>
        groupData.survey_alerts.map(alert => (
          { label: alert.label, key: alert.key, attribute: alert.key }
        ))
      );
      return uniqBy(alerts, 'key');
    },
    surveyAlertsWithValues() {
      const validAlertNames = this.surveyAlertsIndicators.filter(alert => alert.values.some(valueRow => valueRow.value && valueRow.value > 0)).map(alert => alert.name);
      return this.surveyAlerts.filter(survey => validAlertNames.includes(survey.label)).map(alert => ({ sortFunc: (a, b) => a - b, ...alert }));
    },
    surveyAlertsIndicators() {
      return this.surveyAlertsLocalIndicators.map((surveyAlert, index) => ({
        name: surveyAlert.label,
        values: this.surveyAlertsValues[surveyAlert.id],
        color: SURVEY_COLORS[index],
        infoTooltip: this.surveyAlertsTooltip,
        ignoreIfEmpty: true,
      })).filter(alert =>
        alert.values.some(valueRow => valueRow && valueRow.value > 0)
      );
    },
    surveyAlertsValues() {
      const values = this.groupResults.flatMap(groupData =>
        groupData.survey_alerts.map(alert => {
          const value = alert.students_with_alerts_percentage > 0 ? alert.students_with_alerts_percentage : null;
          const extraInfo = alert.count > 0 ? alert.count : null;

          return {
            indicatorId: this.getIndicator(alert.key).id,
            objectId: groupData.group_id,
            value,
            extraInfo: extraInfo === null ? null : `${extraInfo} alertas.`,
            format: value === null ? null : { string: 'percentage' },
            norm_value: null, // eslint-disable-line camelcase
          };
        })
      );
      return groupBy(values, 'indicatorId');
    },
    emnUnderperformingValues() {
      return this.filterByIndicator('numero-alumnos-bajo-desempeño-emn-aptus');
    },
    emnInfoTooltip() {
      const message = 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba EMN Aptus rendida durante el año';
      return this.chosenYear === 2020 ? message.concat(`\n${this.aptusExplanationMessage}`) : message; // eslint-disable-line no-magic-numbers
    },
    pdnUnderperformingValues() {
      return this.filterByIndicator('numero-alumnos-bajo-desempeño-pdn-aptus');
    },
    pdnInfoTooltip() {
      const message = 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba PDN Aptus rendida durante el año';
      return this.chosenYear === 2020 ? message.concat(`\n${this.aptusExplanationMessage}`) : message; // eslint-disable-line no-magic-numbers
    },
    pddUnderperformingValues() {
      return this.filterByIndicator('numero-alumnos-bajo-desempeño-pdd-aptus');
    },
    pddInfoTooltip() {
      const message = 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba PDD Aptus rendida durante el año';
      return this.chosenYear === 2020 ? message.concat(`\n${this.aptusExplanationMessage}`) : message; // eslint-disable-line no-magic-numbers
    },
    mdaUnderperformingValues() {
      return this.filterByIndicator('numero-alumnos-bajo-desempeño-mda-aptus');
    },
    mdaInfoTooltip() {
      const message = 'Porcentaje de estudiantes con promedio logro normalizado catalogado como descendido en la última prueba MDA Aptus rendida durante el año';
      return this.chosenYear === 2020 ? message.concat(`\n${this.aptusExplanationMessage}`) : message; // eslint-disable-line no-magic-numbers
    },
    historicalRepetitionTooltip() {
      return 'Cantidad de estudiantes que alguna vez repitieron de curso en años anteriores';
    },
    surveyAlertsTooltip() {
      return 'PULSO ESCOLAR: Porcentaje de estudiantes con 2 o más alertas en esta encuesta';
    },
    pieEvaluationsTooltip() {
      return 'Porcentaje de evaluaciones PIE terminadas, en progreso y no empezadas.\nPoniendo el mouse sobre cada barra se muestra información adicional.\nDatos obtenidos desde IntégratePIE';
    },
    poptimizeLearningObjectiveCoverageTooltip() {
      return 'OAs cubiertos según plataforma Poptimize';
    },
    poptimizePlannedClassesTooltip() {
      return 'Clases planificadas según plataforma Poptimize';
    },
    chronicAbsenteeismValues() {
      return this.filterByIndicator('porcentaje-inasistencia-cronica-acumulada');
    },
    repetitionRiskValues() {
      return this.filterByIndicator('numero-riesgo-repitencia-grupo');
    },
    repetitionValues() {
      return this.filterByIndicator('numero-alumnos-repitentes-grupo');
    },
    attendanceValues() {
      return this.filterByIndicator('porcentaje-asistencia');
    },
    studentsCountValues() {
      return this.filterByIndicator('numero-alumnos-grupo');
    },
    gradeAverageValues() {
      return this.filterByIndicator('promedio-anual-grupo');
    },
    pieValues() {
      return this.filterByIndicator('numero-alumnos-pie-grupo');
    },
    pieEvaluationsValues() {
      return this.filterByIndicator('evaluaciones-pie');
    },
    groupAdditionalPieValues() {
      return this.filterByIndicator('pie-adicionales');
    },
    preferentialValues() {
      return this.filterByIndicator('numero-alumnos-preferentes-grupo');
    },
    priorityValues() {
      return this.filterByIndicator('numero-alumnos-preferenciales-grupo');
    },
    femalePercentageValues() {
      return this.filterByIndicator('porcentaje-mujeres');
    },
    malePercentageValues() {
      return this.filterByIndicator('porcentaje-hombres');
    },
    femaleCountValues() {
      return this.filterByIndicator('cantidad-mujeres');
    },
    maleCountValues() {
      return this.filterByIndicator('cantidad-hombres');
    },
    historicalRepetitionValues() {
      return this.filterByIndicator('numero-alumnos-repitentes-historico');
    },
    poptimizePlannedClassesValues() {
      return this.filterByIndicator('porcentaje-clases-planificadas-poptimize');
    },
    poptimizeLearningObjectiveCoverageValues() {
      return this.filterByIndicator('porcentaje-oas-cubiertos-poptimize');
    },
    onSiteAttendanceValues() {
      return this.filterByIndicator('promedio-asistencia-presencial');
    },
    genderProportionValues() {
      return this.femalePercentageValues.map(femaleValue => {
        const maleValue = this.malePercentageValues.find(m => femaleValue.objectId === m.objectId);
        if ([0, null].includes(maleValue.value) && [0, null].includes(femaleValue.value)) return { ...femaleValue, value: null };

        const femaleCount = this.femaleCountValues.find(m => femaleValue.objectId === m.objectId).value;
        const maleCount = this.maleCountValues.find(m => femaleValue.objectId === m.objectId).value;
        const totalCount = this.studentsCountValues.find(m => femaleValue.objectId === m.objectId).value;
        const noInfoCount = totalCount - maleCount - femaleCount;
        return {
          ...femaleValue,
          value: [100, 100 - maleValue.value, femaleValue.value], // eslint-disable-line no-magic-numbers
          extraInfo: `Hombres: ${maleCount}. ${noInfoCount > 0 ? `Sin info: ${noInfoCount}. ` : ''}Mujeres: ${femaleCount}`,
          noInfoCount,
        };
      });
    },
    groupPieValues() {
      return this.pieValues.map(pieValue => {
        const pieAdditionalValue = this.groupAdditionalPieValues.find(m => pieValue.objectId === m.objectId);
        const additionalStudents = pieAdditionalValue.value;
        return {
          ...pieValue,
          value: [additionalStudents + pieValue.value, pieValue.value],
          extraInfo: `Oficiales (Sige): ${pieValue.value}. Adicionales (IntégratePIE): ${additionalStudents === null ? 'Sin info' : `${additionalStudents}`}`,
        };
      });
    },
    pieEvaluations() {
      return this.pieEvaluationsValues
        .filter(pieEvaluation => !!pieEvaluation && !!pieEvaluation.value)
        .filter(pieEvaluation => pieEvaluation.value.finished_evaluations + pieEvaluation.value.ongoing_evaluations + pieEvaluation.value.pending_evaluations !== 0)
        .map(pieEvaluation => {
          const {
            finished_evaluations: finishedEvaluations,
            ongoing_evaluations: inProgressEvaluations,
            non_applicable_evaluations: nonApplicableEvaluations,
            pending_evaluations: pendingEvaluations,
          } = pieEvaluation.value;
          const totalEvaluations = finishedEvaluations + inProgressEvaluations + pendingEvaluations; // eslint-disable-line no-magic-numbers
          const extraInfo = `Terminadas: ${finishedEvaluations}<br>
          En progreso: ${inProgressEvaluations}<br>
          No empezadas: ${pendingEvaluations}<br>
          No aplican: ${nonApplicableEvaluations}`;
          return {
            ...pieEvaluation,
            /* eslint-disable no-magic-numbers */
            value: [
              100,
              Math.floor(100 * (inProgressEvaluations + pendingEvaluations) / totalEvaluations),
              Math.floor(100 * (pendingEvaluations) / totalEvaluations),
            ],
            /* eslint-enable no-magic-numbers */
            extraInfo,
          };
        });
    },
    groupPieMaxValue() {
      return Math.max(...this.groupPieValues.map(groupPieValue => groupPieValue.value[0]));
    },
    anyNoInfoGender() {
      return this.genderProportionValues.some(gv => gv.noInfoCount);
    },
    schoolStudentsCount() {
      return this.sumArray(this.studentsCountValues);
    },
    schoolAttendance() {
      return this.avgArray(this.attendanceValues);
    },
    schoolRepetitionCount() {
      return this.sumArray(this.repetitionValues);
    },
    schoolRepetitionRiskCount() {
      return this.sumArray(this.repetitionRiskValues);
    },
    schoolPieCount() {
      if (this.hasAdditionalPieValues) {
        return this.sumArray(this.pieValues.concat(this.groupAdditionalPieValues));
      }
      return this.sumArray(this.pieValues);
    },
    schoolPreferentialCount() {
      return this.sumArray(this.preferentialValues);
    },
    schoolPriorityCount() {
      return this.sumArray(this.priorityValues);
    },
    schoolHistoricalRepetitionCount() {
      return this.sumArray(this.historicalRepetitionValues);
    },
    pulsoAlertsValues() {
      return this.filterByIndicator('alertas-pulso-escolar');
    },
    showPulsoIndicatorBox() {
      return this.pulsoAlertsValues.some(x => !!x.value);
    },
    emnUnderperformingCount() {
      return this.avgArray(this.emnUnderperformingValues);
    },
    pdnUnderperformingCount() {
      return this.avgArray(this.pdnUnderperformingValues);
    },
    pddUnderperformingCount() {
      return this.avgArray(this.pddUnderperformingValues);
    },
    mdaUnderperformingCount() {
      return this.avgArray(this.mdaUnderperformingValues);
    },
    lowReadingQualityCount() {
      return this.avgArray(this.lowReadingQualityValues);
    },
    readingQualityInfoTooltip() {
      return 'Porcentaje de estudiantes con resultados bajo lo esperado en la última medición de Calidad Lectora';
    },
    totalReadingQualityValues() {
      return this.filterByIndicator('numero-de-alumnos-total-calidad-lectora-por-grupo');
    },
    lowReadingQualityValues() {
      return this.filterByIndicator('numero-de-alumnos-bajo-esperado-calidad-lectora-por-grupo').map(lowReadingQuality => {
        const readingQualityTotal = this.totalReadingQualityValues.find(total => total.objectId === lowReadingQuality.objectId).value;
        const totalCount = this.studentsCountValues.find(total => lowReadingQuality.objectId === total.objectId).value;
        return {
          ...lowReadingQuality,
          ...(lowReadingQuality.value ?
            {
              extraInfo: `En este curso fueron evaluados ${readingQualityTotal} estudiantes. Actualmente hay ${totalCount} estudiantes en el curso. De los que rindieron la prueba, un ${lowReadingQuality.value}% estuvo bajo lo esperado`,
            } :
            {}
          ),
        };
      });
    },
    genderProportionTooltip() {
      return 'En verde se muestra el porcentaje de hombres, en morado el porcentaje de mujeres y en gris el porcentaje de estudiantes de los cuales no se tiene información del género en Radar.';
    },
    onSiteAttendanceTooltip() {
      return 'Promedio del total de días de asistencia a clases presenciales por estudiante (acumulado anual)';
    },
    schoolHasRepetition() {
      return this.schoolRepetitionCount !== null;
    },
    indicators() {
      // const pdnInfoTooltip = this.pdnUnderperformingCount ? this.pdnInfoTooltip : 'Si aplicas PDN Aptus, podrás ver tus resultados aquí';
      const pdnInfoTooltip = this.pdnUnderperformingCount ? this.pdnInfoTooltip : '';
      return [
        ...(this.schoolHasRepetition ? [{ name: 'Repitentes', values: this.repetitionValues, color: RED_COLOR, hasTotal: false }] : []),
        ...(this.showRepetitionRisk ? [{ name: this.shortRepetitionRiskLabel, values: this.repetitionRiskValues, color: REPETITION_COLOR, hasTotal: false, infoTooltip: this.repetitionRiskDecree67Tooltip }] : []),
        { name: 'Promedio general', values: this.gradeAverageValues, color: AVERAGE_COLOR, maxValue: 7, reverseSort: true, hasTotal: false,
          ...(this.hasPermission('grades') ? { } : { pro: this.proGradesMessage }) },
        ...(this.useOnSiteAttendances ? [
          { name: 'Días asistencia presencial', values: this.onSiteAttendanceValues, color: ATTENDANCE_COLOR, reverseSort: true, hasTotal: false, infoTooltip: this.onSiteAttendanceTooltip },
        ] : [
          { name: 'Promedio asistencia', values: this.attendanceValues, color: ATTENDANCE_COLOR, maxValue: 100, reverseSort: true, hasTotal: false },
          { name: 'Ausentes crónicos', values: this.chronicAbsenteeismValues, color: CHRONIC_COLOR, hasTotal: false },
        ]),
        ...(this.surveyAlertsIndicators),
        //...(this.showPulsoIndicatorBox ? [{ name: 'Alertas de Pulso Escolar', values: this.pulsoAlertsValues, color: PULSO_COLOR, infoTooltip: 'PULSO ESCOLAR: Porcentaje de alumnos con 2 o más alertas' }] : []),
        // ...(this.emnUnderperformingCount ? [{ name: 'EMN Aptus bajo desempeño', values: this.emnUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: this.emnInfoTooltip, aggrValue: this.emnUnderperformingCount, hasTotal: false, aggrFormat: 'percentageWithOneDecimal' }] : []),
        // ...(this.pdnUnderperformingCount || this.userPermissions.aptus_client ?
        //   [{ name: 'PDN Aptus bajo desempeño', values: this.pdnUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: pdnInfoTooltip, aggrValue: this.pdnUnderperformingCount, hasTotal: false, aggrFormat: 'percentageWithOneDecimal' }] :
        //   []),
        // ...(this.pddUnderperformingCount ?
        //   [{ name: 'PDD Aptus bajo desempeño', values: this.pddUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: this.pddInfoTooltip, aggrValue: this.pddUnderperformingCount, hasTotal: false, aggrFormat: 'percentageWithOneDecimal' }] :
        //   []),
        // ...(this.mdaUnderperformingCount ?
        //   [{ name: 'MDA Aptus bajo desempeño', values: this.mdaUnderperformingValues, color: UNDERPERFORMING_COLOR, infoTooltip: this.mdaInfoTooltip, aggrValue: this.mdaUnderperformingCount, hasTotal: false, aggrFormat: 'percentageWithOneDecimal' }] :
        //   []),

        ...(this.lowReadingQualityCount ? [{ name: 'C. Lectora bajo desempeño', values: this.lowReadingQualityValues, color: LOW_READING_QUALITY, infoTooltip: this.readingQualityInfoTooltip, aggrValue: this.lowReadingQualityCount, hasTotal: false, aggrFormat: 'percentageWithOneDecimal' }] : []),
        ...(this.historicalRepetitionValues ?
          [{ name: 'Repitentes históricos', values: this.historicalRepetitionValues, color: REPETITION_COLOR,
            infoTooltip: this.historicalRepetitionTooltip, aggrValue: this.schoolHistoricalRepetitionCount, hasTotal: true,
          }] : []),
        ...(this.hasGenderProportionValues ? [{ name: 'Proporción hombres y mujeres', values: this.genderProportionValues, showComplement: true, infoTooltip: this.genderProportionTooltip, color: [MALE_COLOR, DARK_GRAY_COLOR, FEMALE_COLOR], maxValue: 100, legends: [{ color: MALE_COLOR, label: 'M' }, this.anyNoInfoGender ? { color: DARK_GRAY_COLOR, label: 'S/I' } : {}, { color: FEMALE_COLOR, label: 'F' }], interpolateColors: false }] : []),
        ...(this.hasAdditionalPieValues ? [{
          name: 'PIE', values: this.groupPieValues, color: [PIE_ADDITIONAL_COLOR, PIE_COLOR], maxValue: this.groupPieMaxValue, interpolateColors: false, aggrValue: this.schoolPieCount, showFirstValue: true, hasTotal: true, specialSort: true, legends: [{ color: PIE_ADDITIONAL_COLOR, label: 'Adicional' }, { color: PIE_COLOR, label: 'Oficial' }],
          sortFunc(a, b) {
            const sumA = a.value[0] || 0 + a.value[1] || 0;
            const sumB = b.value[0] || 0 + b.value[1] || 0;
            return sumA === sumB ? b.value[1] - a.value[1] : sumB - sumA;
          },
        }] : [{ name: 'PIE', values: this.pieValues, color: PIE_COLOR, aggrValue: this.schoolPieCount, hasTotal: true }]),
        ...(this.hasPieEvaluations ? [{
          name: 'Evaluaciones PIE', values: this.pieEvaluations, infoTooltip: this.pieEvaluationsTooltip,
          legends: [{ color: SEMAPHORE_COLORS.high, label: 'Terminadas' }, { color: SEMAPHORE_COLORS.middle, label: 'En progreso' }, { color: SEMAPHORE_COLORS.low, label: 'No empezadas' }], color: [SEMAPHORE_COLORS.high, SEMAPHORE_COLORS.middle, SEMAPHORE_COLORS.low],
          maxValue: 100, showComplement: true, interpolateColors: false,
        }] : []),
        { name: 'Prioritarios', values: this.priorityValues, color: PRIORITY_COLOR, aggrValue: this.schoolPriorityCount, hasTotal: true },
        { name: 'Preferentes', values: this.preferentialValues, color: PREFERENTIAL_COLOR, aggrValue: this.schoolPreferentialCount, hasTotal: true },
        ...(this.hasPoptimizePlannedClassesValues ? [{
          name: 'Porcentaje clases planificadas', values: this.poptimizePlannedClassesValues, color: PLANNED_CLASSES_COLOR,
          maxValue: 50, reverseSort: true, hasTotal: false, infoTooltip: this.poptimizePlannedClassesTooltip,
        }] : []),
        ...(this.hasPoptimizeLearningObjectiveCoverageValues ? [{
          name: 'Porcentaje OAs cubiertos', values: this.poptimizeLearningObjectiveCoverageValues, color: LEARNING_OBJECTIVE_COVERAGE_COLOR,
          maxValue: 75, reverseSort: true, hasTotal: false, infoTooltip: this.poptimizeLearningObjectiveCoverageTooltip,
        }] : []),
      ].filter(indicator => !(indicator.aggrValue === 0));
    },
    hasGenderProportionValues() {
      return this.genderProportionValues.some(({ value }) => !!value);
    },
    hasAdditionalPieValues() {
      return this.groupAdditionalPieValues.some(({ value }) => !!value);
    },
    hasPieEvaluations() {
      return this.pieEvaluations.some(({ value }) => !!value);
    },
    hasPoptimizePlannedClassesValues() {
      return this.poptimizePlannedClassesValues.some(({ value }) => !!value);
    },
    hasPoptimizeLearningObjectiveCoverageValues() {
      return this.poptimizeLearningObjectiveCoverageValues.some(({ value }) => !!value);
    },
    hasAttendanceValues() {
      return this.attendanceValues.some(({ value }) => !!value);
    },
    attendanceAggregateBoxText() {
      return this.useOnSiteAttendances ? 'días asistencia presencial' : 'prom. asistencia';
    },
    attendanceAggregateBoxFormat() {
      return this.useOnSiteAttendances ? 'numberWithOneDecimal' : 'percentageWithOneDecimal';
    },
    attendanceAggregateBoxValue() {
      return this.useOnSiteAttendances ? this.schoolOnSiteAttendanceResult : this.schoolAttendanceResult;
    },
    attendanceAggregateBoxInfoTooltip() {
      return this.useOnSiteAttendances ? this.onSiteAttendanceTooltip : '';
    },
    hasOnSiteAttendanceValues() {
      return this.onSiteAttendanceValues.some(({ value }) => !!value);
    },
    schoolAttendanceIndicator() {
      return this.resultsIndicators
        .find(i => i.key === 'porcentaje-asistencia-anual-colegio') || {};
    },
    schoolChronicAbsenteeismIndicator() {
      return this.resultsIndicators
        .find(i => i.key === 'porcentaje-inasistencia-cronica-acumulada') || {};
    },
    schoolOnSiteAttendanceResult() {
      const sum = this.groupResults.reduce((a, b) => ({ on_site_attendance_average: a.on_site_attendance_average + b.on_site_attendance_average })).on_site_attendance_average; // eslint-disable-line camelcase
      return this.groupResults.length ? sum / this.groupResults.length : 0;
    },
    schoolAttendanceResult() {
      const result = this.results.find(
        r => r.indicator_id === this.schoolAttendanceIndicator.id,
      );
      return result && result.value || null;
    },
    schoolChronicAbsenteeismResult() {
      const maxMonth = Math.max(...this.results.map(r => r.month));
      const result = this.results.find(r => (
        r.indicator_id === this.schoolChronicAbsenteeismIndicator.id &&
        r.month === maxMonth
      ));
      return result && result.value || null;
    },
    repetitionRiskPresent() {
      return this.repetitionRiskValues.some(group => group.value !== null);
    },
    showPdnTeaser() {
      return this.rows.every(a => a.pdn_underperforming_percentage === null);
    },
    isCurrentSchoolYearChosen() {
      return this.chosenYear === schoolYear();
    },
  },
  watch: {
    options: {
      handler: 'fetchSchoolReport',
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('results', {
      setResults: 'SET_RESULTS',
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    ...mapActions('options', ['setChosenGroup']),
    async fetchSchoolReport() {
      this.addLoading();
      try {
        const { body } = await SchoolsApi.getSchoolReport({
          schoolId: this.chosenSchoolId,
          year: this.chosenYear,
        });
        this.setResults(body.school_report.summary);
        this.groupResults = body.school_report.group_results;
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
      }
    },
    hasValues(column, groupResults) {
      function numOrZero(n) {
        return isNaN(n) ? 0 : n;
      }
      const total = groupResults.map(result => numOrZero(result[column.key])).reduce(
        (a, b) => a + b,
        0,
      );
      return total !== 0;
    },
    getIndicator(key) {
      return this.localIndicators.find(i => i.key === key);
    },
    invalidResult(indicatorKey, groupData) {
      const group = this.groups.find(g => g.id === groupData.group_id);
      if (!group) return true;

      const groupKey = group.key;
      if (indicatorKey === 'promedio-anual-grupo' && groupKey.match('(PK|K).*')) {
        return true;
      }

      return false;
    },
    resultHash(indicatorKey, groupData) {
      const attribute = groupData[this.getIndicator(indicatorKey).attribute];
      const format = this.formatData(indicatorKey);
      const value = this.valueData(attribute);

      return {
        indicatorId: this.getIndicator(indicatorKey).id,
        objectId: groupData.group_id,
        value,
        format: format && (value || value === 0) ? { string: this.formatData(indicatorKey) } : null,
        norm_value: this.normValueFromData(attribute), // eslint-disable-line camelcase
        class: this.classFromData(attribute),
      };
    },

    formatData(indicatorKey) {
      if (indicatorKey) {
        if ([
          'porcentaje-mujeres',
          'porcentaje-inasistencia-cronica-acumulada',
          'porcentaje-asistencia',
          'evaluaciones-pie',
          'porcentaje-clases-planificadas-poptimize',
          'porcentaje-oas-cubiertos-poptimize',
          'alertas-pulso-escolar',
          ...(this.dashboard ? [] : ['numero-alumnos-bajo-desempeño-emn-aptus']),
          ...(this.dashboard ? [] : ['numero-alumnos-bajo-desempeño-pdn-aptus']),
          ...(this.dashboard ? [] : ['numero-alumnos-bajo-desempeño-pdd-aptus']),
          ...(this.dashboard ? [] : ['numero-alumnos-bajo-desempeño-mda-aptus']),
        ].includes(indicatorKey)) {
          return 'percentage';
        } else if ([
          ...(this.dashboard ? ['numero-alumnos-bajo-desempeño-emn-aptus'] : []),
          ...(this.dashboard ? ['numero-alumnos-bajo-desempeño-pdn-aptus'] : []),
          ...(this.dashboard ? ['numero-alumnos-bajo-desempeño-pdd-aptus'] : []),
          ...(this.dashboard ? ['numero-alumnos-bajo-desempeño-mda-aptus'] : []),
          ...(this.dashboard ? ['numero-de-alumnos-bajo-esperado-calidad-lectora-por-grupo'] : []),
        ].includes(indicatorKey)) {
          return 'percentageWithOneDecimal';
        } else if ([
          'promedio-anual-grupo',
          'promedio-asistencia-presencial',
        ].includes(indicatorKey)) {
          return 'numberWithOneDecimal';
        }
      }
      return null;
    },
    valueData(data) {
      if (data && (data.value || data.value === 0)) return data.value;
      if (data && !data.value && data.alternative_text) return data.alternative_text;
      if (data && data.value === null) return null;

      return data;
    },
    classFromData(data) {
      if (Number.isInteger(data)) {
        return this.$style.centered;
      }

      return this.$style.color;
    },
    normValueFromData(data) {
      if (data && data.norm_value) return data.norm_value;
      return data;
    },
    filterByIndicator(indicatorKey) {
      const indicator = this.localIndicators.find(x => x.key === indicatorKey);
      return this.valuesPlusGroup.filter(obj => obj.indicatorId === indicator.id);
    },
    sumArray(array) {
      if (array.every(elem => elem.value === null)) {
        return null;
      }

      return array.reduce((sum, obj) => sum + obj.value, 0);
    },
    avgArray(array) {
      const validArray = array.filter(x => x.value !== null);

      return validArray.length === 0 ? null : validArray.reduce((sum, obj) => sum + obj.value, 0) / (validArray.length || 1);
    },
    avgWeightedArray(array, weights) {
      if (array.every(elem => elem.value === null)) {
        return null;
      }

      return array.reduce((sum, obj) => sum + obj.value * weights[obj.objectId], 0) / (this.schoolStudentsCount || 1);
    },
    educationTypePrefix(educationType) {
      if (educationType.startsWith('2')) return '2';
      switch (educationType) {
      case '10':
        return '1';
      case '110':
        return '3';
      case '310': case '410': case '510': case '610': case '710': case '810': case '910':
        return '4';
      default:
        return '5';
      }
    },
    groupOrder(group) {
      const prefix = this.educationTypePrefix(group.education_type);
      const groupComponent = group.grade === null ? 0 : group.grade.toString();
      const alphabet = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'.split('');
      const letterToNumber = alphabet.indexOf(group.letter);
      const letterComponent = this.$options.filters.numberWithTwoDigits(letterToNumber);
      const stem = groupComponent.concat(letterComponent);
      const suffix = group.education_type.toString();
      return prefix.concat(stem, suffix);
    },
    /* eslint-disable camelcase */
    resultToRow(result, index) {
      const max = result.student_count / 2; // eslint-disable-line no-magic-numbers
      const avg = result.student_count / 4; // eslint-disable-line no-magic-numbers
      const group = this.groups.find(g => result.group_id === g.id);

      return {
        ...result,
        grade_average_value: result.grade_average.value || result.grade_average.alternative_text,
        attendance: result.attendance_average.value,
        attendance_norm_value: result.attendance_average.norm_value,
        chronic_absenteeism_count: result.chronic_absenteeism_data.value, // eslint-disable-line no-magic-numbers
        chronic_absenteeism_count_norm: normalize(
          result.chronic_absenteeism_average.value, 0, avg, max // eslint-disable-line no-magic-numbers
        ),
        pdn_underperforming_count: result.pdn_underperforming_data.value,
        pdn_underperforming_count_norm: normalize(
          result.pdn_underperforming_percentage, 0, 25, 50), // eslint-disable-line no-magic-numbers
        pdd_underperforming_count: result.pdd_underperforming_data.value,
        pdd_underperforming_count_norm: normalize(
          result.pdd_underperforming_percentage, 0, 25, 50), // eslint-disable-line no-magic-numbers
        mda_underperforming_count: result.mda_underperforming_data.value,
        mda_underperforming_count_norm: normalize(
          result.mda_underperforming_percentage, 0, 25, 50), // eslint-disable-line no-magic-numbers
        emn_underperforming_count: result.emn_underperforming_data.value,
        emn_underperforming_count_norm: normalize(
          result.emn_underperforming_percentage, 0, 25, 50), // eslint-disable-line no-magic-numbers
        low_reading_quality_count: result.low_reading_quality_data.value,
        low_reading_quality_count_norm: normalize(
          result.low_reading_quality_percentage.value, 0, 25, 50), // eslint-disable-line no-magic-numbers
        repetition_risk_count_norm: normalize(result.repetition_risk_count, 0, avg, max),
        repetition_count_norm: normalize(result.repetition_count, 0, avg, max),
        historical_repetition_count_norm: normalize(result.historical_repetition_count, 0, avg, max),
        pie_count_norm: normalize(result.pie_count, 0, avg, max),
        pie_additional_value: result.pie_additional && result.pie_additional.value,
        pie_additional_norm: result.pie_additional && normalize(result.pie_additional.value, 0, avg, max),
        preferential_count_norm: normalize(result.preferential_count, 0, avg, max),
        priority_count_norm: normalize(result.priority_count, 0, avg, max),
        poptimize_planned_classes_percentage_norm: normalize(result.poptimize_planned_classes_percentage, 0, 25, 50), // eslint-disable-line no-magic-numbers
        poptimize_learning_objective_coverage_percentage_norm: normalize(result.poptimize_learning_objective_coverage_percentage, 0, 37.5, 75), // eslint-disable-line no-magic-numbers
        group: group.short_name,
        groupOrderKey: this.groupOrder(group),
        group_key: group.key,
        id: index,
        ...(this.surveyAlertsRows(result)),
      };
    },
    surveyAlertsRows(result) {
      const rows = {};
      this.surveyAlerts.forEach(surveyAlert => {
        rows[surveyAlert.key] = result.survey_alerts.find(survey_alert => survey_alert.key === surveyAlert.key).count;
      });
      return rows;
    },
    /* eslint-enable camelcase */
    async handleClick({ group_key }) { // eslint-disable-line camelcase
      await this.setChosenGroup(group_key);
      this.$router.push({
        name: 'groupReport',
        params: { school: this.chosenSchoolId, year: this.chosenYear },
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

  .school-filter-table {
    padding: 0 30px;
    margin-top: 15px;

    &-empty {
      margin-left: 0;
      text-align: center;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }
  }

  .centered {
    text-align: center;
    border: 2px;
  }

  .color {
    text-align: center;
    border: 2px solid;
  }

  .centered-value {
    border: 2px;
    padding-left: '5px';
    padding-right: '5px';
    text-align: center;
  }

  .loading {
    position: absolute !important;
    margin-top: 0 !important;
  }

  .info-tooltip {
    margin: 0 20px 20px 0;
  }

  .dashboard-elements {
    &-aggregate-box {
      display: flex;
      flex-basis: 30%;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: $mobile-breakpoint) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-indicator-box {
      display: grid;
      grid-template-columns: repeat(3, 30%);
      justify-content: space-between;
      @media only screen and (max-width: $mobile-breakpoint) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

table.school-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background: #F7F7F7;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $cards-background-border;
        background: white;
      }
    }
    tr:hover {
      td {
        background-color: #E7EFFF;
      }
    }
  }
}

div.school-report-header-cell {
  height: 42px;
}

div.school-report-header-cell {
  height: 42px;
}

</style>
