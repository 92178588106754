const PRIMARY_COLOR = '#4266f7';
const SUCCESS_COLOR = '#22cf63';
const GREEN_COLOR = '#5AAC27';
const LIGHT_COLOR = '#aaaac0';
const IMPORTANT_COLOR = '#f18f01';
const MAIN_TEXT_COLOR = '#6e6e75';
const BLUE_GRAY_TEXT_COLOR = '#555e81';
const LIGHT_GRAY_COLOR = '#c1c1c5';
const DARK_GRAY_COLOR = '#808087';
const REPETITION_COLOR = '#c27ba0';
const CHRONIC_COLOR = '#f28773';
const ATTENDANCE_COLOR = '#93c47d';
const AVERAGE_COLOR = '#0097a7';
const PIE_COLOR = '#ffab40';
const PIE_TEMPORARY_COLOR = '#ffc985';
const PIE_PERMANENT_COLOR = '#fe7b20';
const PIE_ADDITIONAL_COLOR = '#f28774';
const REPETITION_RISK_GRADES_COLOR = '#cc2900';
const REPETITION_RISK_ABSENTEEISM_COLOR = '#ff9980';
const PREFERENTIAL_COLOR = '#6d9eeb';
const PRIORITY_COLOR = '#8e7cc3';
const SIDEBAR_BACKGROUND_COLOR = '#f9f9fd';
const WHITE_COLOR = '#ffffff';
const APPROVED_COLOR = '#6483FB';
const FAIL_COLOR = '#DF6666';
const INCREASE_COLOR = '#B3D595';
const DECREASE_COLOR = '#E6837A';
const RED_COLOR = '#E84136';
const UNDERPERFORMING_COLOR = '#e06666';
const LOW_READING_QUALITY = '#cc4343';
const SEMAPHORE_COLORS = { low: '#ED050B', middle: '#fbbb13', high: '#05BD3C' };
const FEMALE_COLOR = '#b19cf0';
const MALE_COLOR = '#93c47d';
const GRAY_BACKGROUND_COLOR = '#fbfaff';
const TEXT_GRADE_COLOR = '#000000';
const SURVEY_COLORS = [
  '#c29fff', '#f4a995', '#93f6af', '#00c9f8', '#de7b39', '#cc2977', '#6d9ffd', '#4a2fd9',
];
const PLANNED_CLASSES_COLOR = '#00bbca';
const LEARNING_OBJECTIVE_COVERAGE_COLOR = '#d60013';
const PULSO_COLOR = '#c29fff';

const GREEN_BEHAVIOR_COLOR = '#5AAC27';
const YELLOW_BEHAVIOR_COLOR = '#FFCF54';
const RED_BEHAVIOR_COLOR = '#FF4F56';


export {
  PRIMARY_COLOR,
  SUCCESS_COLOR,
  GREEN_COLOR,
  LIGHT_COLOR,
  IMPORTANT_COLOR,
  MAIN_TEXT_COLOR,
  BLUE_GRAY_TEXT_COLOR,
  LIGHT_GRAY_COLOR,
  DARK_GRAY_COLOR,
  REPETITION_COLOR,
  CHRONIC_COLOR,
  ATTENDANCE_COLOR,
  AVERAGE_COLOR,
  PIE_COLOR,
  PIE_TEMPORARY_COLOR,
  PIE_PERMANENT_COLOR,
  PIE_ADDITIONAL_COLOR,
  REPETITION_RISK_GRADES_COLOR,
  REPETITION_RISK_ABSENTEEISM_COLOR,
  PREFERENTIAL_COLOR,
  PRIORITY_COLOR,
  SIDEBAR_BACKGROUND_COLOR,
  WHITE_COLOR,
  APPROVED_COLOR,
  FAIL_COLOR,
  INCREASE_COLOR,
  DECREASE_COLOR,
  TEXT_GRADE_COLOR,
  RED_COLOR,
  UNDERPERFORMING_COLOR,
  LOW_READING_QUALITY,
  SEMAPHORE_COLORS,
  FEMALE_COLOR,
  MALE_COLOR,
  GRAY_BACKGROUND_COLOR,
  SURVEY_COLORS,
  PLANNED_CLASSES_COLOR,
  LEARNING_OBJECTIVE_COVERAGE_COLOR,
  PULSO_COLOR,
  GREEN_BEHAVIOR_COLOR,
  YELLOW_BEHAVIOR_COLOR,
  RED_BEHAVIOR_COLOR,
};
