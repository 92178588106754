<template>
  <div class="group-report__container">
    <div v-if="summaryLoading">
      <transition name="fade">
        <loading-overlay/>
      </transition>
    </div>
    <div v-else>
      <!-- COMENTADO TEMPORALMERNTE == v-if="Object.entries(summaryInfo).length > 0" REPPLAZADO POR VARIABLE === summaryBoolean -->
      <div
        v-if="summaryBoolean"
        :class="$style.summary">
        <div :class="[$style.box, $style.boxLeft]">
          <span :class="$style.subTitle">
            Profesor jefe
          </span>
          <span :class="$style.teacherName">
            {{ headTeacher || '—' }}
          </span>
          <span :class="$style.subTitle">
            Promedio de calificaciones
          </span>
          <span
            v-if="hasGradesPermission"
            :class="$style.title">
            <data-box
              :value="summaryInfo.grade_average && summaryInfo.grade_average.value"
              :norm-value="summaryInfo.grade_average && summaryInfo.grade_average.value"
              :colors="colors"
              :max-value="7"
              :min-value="4"
              format="numberWithOneDecimal"
              size="big" />
          </span>
          <rdr-tooltip v-else>
            {{ proGradesMessage }}
            <i
              slot="reference"
              class="material-icons material-icons-light-grey">
              help
            </i>
          </rdr-tooltip>
          <span :class="$style.subTitle">
            {{ attendanceDataBoxSubTitle }}
          </span>
          <span :class="$style.title">
            <data-box
              :value="attendanceDataBoxValue"
              :norm-value="summaryInfo.attendance_average && summaryInfo.attendance_average.norm_value"
              :colors="colors"
              :format="attendanceDataBoxFormat"
              size="big" />
          </span>
          <span :class="$style.subTitle">
            Cantidad alertas Pulso Escolar
          </span>
          <span :class="[$style.title, $style.surveyAlertsWrapper]">
            <!--<div v-if="surveyIndicators.length">-->
            <div v-if="surveyIndicatorsBolean">
              <rdr-tooltip
                v-for="indicator in surveyIndicators"
                :key="indicator.key">
                {{ indicator.name }}
                <div
                  slot="reference"
                  :class="$style.surveyAlertsDataBox">
                  <data-box
                    :value="indicator.value"
                    :colors="indicator.color"
                    format="number"
                    size="big" />
                </div>
              </rdr-tooltip>
            </div>
            <div v-else>
              —
            </div>
          </span>
          <span
            v-if="lowReadingQualityValue"
            :class="$style.subTitle" >
            Estudiantes bajo desempeño calidad lectora
          </span>
          <span :class="[$style.title, $style.surveyAlertsWrapper]">
            <div v-if="lowReadingQualityValue">
              <div
                :class="$style.title">
                <data-box
                  :value="lowReadingQualityValue"
                  :norm-value="summaryInfo.low_reading_quality_data && summaryInfo.low_reading_quality_data.norm_value"
                  format="number"
                  size="big" />
              </div>
            </div>
          </span>
        </div>
        <div :class="[$style.box, $style.boxRight]">
          <div
            v-for="indicator in indicators"
            :key="indicator.name"
            :class="$style.row">
            <div :class="$style.indicator">
              {{ indicator.name }}
            </div>
            <div :class="$style.value">
              {{ indicator.value }}
            </div>
            <div :class="$style.bar">
              <progress-bar
                :class="$style.progress"
                :line-color="indicator.color"
                :background-color="SIDEBAR_BACKGROUND_COLOR"
                :progress="percentage(indicator.value) * 100"
                :height="8"
                :round="2"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="loadTable">
        <div v-if="loadNewData" :class="$style.table">
          <rdr-table
            :columns="columns"
            :data="rowsPaginated"
            :click="handleClick"
            :custom-class="$style.courseReportTable"
            :extended-header-class="$style.courseReportHeaderCell"
            :extended-cell-class="$style.courseReportCell"
            :sticky-top-offset="stickyTopOffset"
            :hidden-columns-count="hiddenColumnsCount"
            :max-label-length="30"
            :showRowIndexNew="numberRow"
          />
        </div>
        <div v-if="loadPager">
          <paginador-front :table-list="rows" @items-return="onItems"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Pagination  } from 'element-ui';
import PermissionMixin from '../../mixins/permission';
import AuthMixin from '../../mixins/auth';
import Decree67Mixin from '../../mixins/decree-67';
import TooltipInfoMixin from '../../mixins/tooltip-info';
import GroupStudentsApi from '../../api/groups';
import DataBox from '../../components/utils/data-box';
import ProgressBar from '../../components/base/progress-bar';
import PaginadorFront from '../../components/paginador-front.vue';
import {
  REPETITION_COLOR, CHRONIC_COLOR, PIE_TEMPORARY_COLOR,
  PIE_PERMANENT_COLOR, PREFERENTIAL_COLOR, PRIORITY_COLOR, WHITE_COLOR, PULSO_COLOR,
  REPETITION_RISK_ABSENTEEISM_COLOR, REPETITION_RISK_GRADES_COLOR, SURVEY_COLORS,
  PRIMARY_COLOR, SIDEBAR_BACKGROUND_COLOR, PIE_COLOR, RED_COLOR, PIE_ADDITIONAL_COLOR,
  GREEN_BEHAVIOR_COLOR, YELLOW_BEHAVIOR_COLOR, RED_BEHAVIOR_COLOR,
} from '../../utils/style-variables';
import { repetitionRiskValueToMessage } from '../../utils/repetition-risk';
import orderedArrayByIndexToHash from '../../utils/ordered-array-to-hash';
import { MONTHS, TERM_NAMES } from '../../utils/months';


const HIDDEN_COLUMNS_COUNT = 4;
const RANGE_KEYS = {
  within: 'withinRange',
  above: 'aboveRange',
  below: 'belowRange',
};
const REPETITION_RISK_KEYS = {
  absenteeism: 'absenteeism',
  grades: 'grades',
};
const REPETITION_RISK_ORDER_ARRAY = [0, null, 3, 1, 2, 4, 5]; // eslint-disable-line no-magic-numbers
const QUALITY_NAME_MAPPINGS = {
  0: 'NL',
  1: 'S',
  2: 'PP',
  3: 'UC',
  4: 'F',
};

export default {
  name: 'GroupReportSummaryView',
  components: {
    DataBox,
    ProgressBar,
    'el-pagination': Pagination,
    PaginadorFront,
  },
  mixins: [PermissionMixin, AuthMixin, Decree67Mixin, TooltipInfoMixin],
  props: {
    stickyTopOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      error: false,
      rowsPaginated: [],
      loadNewData: true,
      loadTable: false,
      summaryLoading: true,
      conteo: 0,
      studentsLoading: 0,
      summaryInfo: {},
      SIDEBAR_BACKGROUND_COLOR,
      students: [],
      colors: ['#e06666', '#fffdcc', '#93c47d'],
      repetitionInfoWarning: 'Falta información para calcular el riesgo de repitencia',
      proGradesMessage: 'Con el plan Académico de RadarEscolar podrías ver acá el promedio de calificaciones actual de este estudiante',
      aptusExplanationMessage: 'Importante: El logro normalizado de las evaluaciones 2020 de Aptus se realizó con una referencia de colegios distinta a SIP red de colegios.',
      numberRow: 0,
      summaryBoolean: false,
      surveyIndicatorsBolean: false,
      loadPager: false,
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolId', 'chosenYear', 'chosenGroupKey']),
    infoWarning() {
      return `Estudiante con ${this.repetitionRiskLabel.toLowerCase()} por calificaciones y ausentismo crónico`;
    },
    options() {
      return {
        schoolId: this.chosenSchoolId,
        groupKey: this.chosenGroupKey,
        year: this.chosenYear,
      };
    },
    studentOptions() {
      return {
        year: this.year,
        retired: false,
      };
    },
    showRepetitionRisk() {
      return !this.groupHasRepetition && this.summaryInfo.repetition_risk_count !== null;
    },
    surveyAlerts() {
      const alerts = this.summaryInfo.survey_alerts;
      return Array.isArray(alerts) ? alerts.filter(({ count }) => count > 0) : [];
    },
    indicators() {
      return [
        { name: 'Total estudiantes', value: this.summaryInfo.student_count, color: PRIMARY_COLOR },
        ...(this.showRepetitionRisk ? [{ name: this.repetitionRiskLabel, value: this.summaryInfo.repetition_risk_count, color: REPETITION_COLOR }] : []),
        ...(this.useOnSiteAttendances ? [] : [{ name: 'Ausentes crónicos', value: this.summaryInfo.chronic_absenteeism_count, color: CHRONIC_COLOR }]),
        { name: 'Repitentes históricos', value: this.summaryInfo.historical_repetition_count, color: RED_COLOR, ignoreIfEmpty: false },
        { name: 'PIE', value: this.summaryInfo.pie_count, color: PIE_COLOR, ignoreIfEmpty: true },
        ...(this.hasAdditionalPie ? [{ name: 'PIE Adicionales', value: this.summaryInfo.pie_additional && this.summaryInfo.pie_additional.value, color: PIE_ADDITIONAL_COLOR }] : []),
        { name: 'Preferentes', value: this.summaryInfo.preferential_count, color: PREFERENTIAL_COLOR, ignoreIfEmpty: true },
        { name: 'Prioritarios', value: this.summaryInfo.priority_count, color: PRIORITY_COLOR, ignoreIfEmpty: true },
        ...(this.groupHasRepetition ? [{ name: 'Repitentes', value: this.summaryInfo.repetition_count, color: RED_COLOR, ignoreIfEmpty: false }] : []),
        { name: 'Anotaciones negativas', value: 4, color: RED_BEHAVIOR_COLOR, ignoreIfEmpty: true },
        { name: 'Anotaciones neutras', value: 12, color: YELLOW_BEHAVIOR_COLOR, ignoreIfEmpty: true },
        { name: 'Anotaciones positivas', value: 8, color: GREEN_BEHAVIOR_COLOR, ignoreIfEmpty: true },
      ].filter(indicator => !indicator.ignoreIfEmpty || indicator.value > 0);
    },
    surveyIndicators() {
      return this.surveyAlerts.length ? this.surveyAlerts.map((surveyAlert, index) => ({
        key: surveyAlert.key,
        name: surveyAlert.label,
        value: surveyAlert.count,
        color: [WHITE_COLOR, SURVEY_COLORS[index]],
      })) : [];
    },
    hasAttendanceValues() {
      return this.rows.some(r => !!(r.attendanceValue || r.attendanceNormValue));
    },
    hasOnSiteAttendanceValues() {
      return this.rows.some(r => !!r.onSiteAttendanceValue);
    },
    useOnSiteAttendances() {
      return !this.hasAttendanceValues;
    },
    attendanceDataBoxSubTitle() {
      return this.useOnSiteAttendances ? 'Promedio de asistencia presencial' : 'Promedio de asistencia';
    },
    attendanceDataBoxValue() {
      return this.useOnSiteAttendances ? this.summaryInfo.on_site_attendance_average : this.summaryInfo.attendance_average.value;
    },
    attendanceDataBoxFormat() {
      return this.useOnSiteAttendances ? 'numberWithOneDecimal' : 'percentageWithOneDecimal';
    },
    onSiteAttendanceHeaderHover() {
      return 'Total de días de asistencia presencial a clases durante el año';
    },
    columns() {
      return [
        { key: 'full_name', label: 'Nombre', textAlign: 'left', sortFunc: (a, b) => a.localeCompare(b), warning: !this.groupHasRepetition, warningFunction: this.warningFunction, infoWarning: this.infoWarning, minWidth: 150, sticky: true },
        {
          key: 'finalGradeValue', label: 'Promedio', type: 'data-box', maxValue: 7, minValue: 4, format: 'numberWithOneDecimal', sortFunc: (a, b) => a - b,
          ...(this.hasGradesPermission ? { colors: this.colors, norm: 'finalGradeValue' } : { pro: this.proGradesMessage, colors: ['#fff'] }),
        },
        ...(this.useOnSiteAttendances ?
          [
            {
              key: 'onSiteAttendanceValue', label: 'Días Asistencia Presencial', format: 'number', type: 'data-box', sortFunc: (a, b) => a - b,
              colors: this.colors, headerHover: this.onSiteAttendanceHeaderHover,
            },
          ] :
          [
            { key: 'attendanceValue', label: 'Asistencia', norm: 'attendanceNormValue', format: 'percentage', type: 'data-box', sortFunc: (a, b) => a - b, colors: this.colors },
            { key: 'chronicAbsenteeism', label: 'Ausente crónico', type: 'data-box', icon: 'warning', sortFunc: (a, b) => a - b, colors: [CHRONIC_COLOR] },
          ]),
        ...this.surveyColumns,
        // ...(this.studentsHavePulsoColumn ?
        //   [{
        //     key: 'pulsoAlertsValue', label: 'Alertas Pulso Escolar', format: 'number',
        //     type: 'data-box',
        //     headerHover: 'Cantidad de Alertas en Pulso Escolar',
        //     hover: true, hoverCheckFunction: this.pulsoCheckFunction,
        //     hoverShowValue: this.pulsoAlertsTooltip,
        //     sortFunc: (a, b) => a - b,
        //     colors: [PULSO_COLOR],
        //   }] : []),
        // ...(this.studentsHaveEmnColumn ?
        //   [{
        //     key: 'emnAverageValue', label: `EMN Aptus ${this.emnLatestMonth}`, format: 'numberWithOneDecimal',
        //     type: 'data-box', backgroundType: 'discrete',
        //     headerHover: this.showAptusExplanation(`Promedio normalizado Aptus en EMN ${this.emnLatestMonth}`),
        //     hover: true, hoverCheckFunction: this.emnCheckFunction, hoverShowValue: this.emnHoverValue,
        //     sortFunc: (a, b) => a - b,
        //     colors: this.colors,
        //     norm: 'emnAverageNormValue',
        //     discreteColors: { [RANGE_KEYS.below]: '#e06666', [RANGE_KEYS.within]: '#fffdcc', [RANGE_KEYS.above]: '#93c47d' },
        //     getColorIndexValue: this.discretizeEMNValues({ maxValue: 0.1, minValue: -0.3 }),
        //   }] : []),
        // ...(this.studentsHavePdnColumn || this.userPermissions.aptus_client ?
        //   [{
        //     key: 'pdnAverageValue', format: 'numberWithOneDecimal',
        //     label: this.pdnLatestMonth ? `PDN Aptus ${this.pdnLatestMonth}` : 'PDN Aptus',
        //     type: 'data-box', backgroundType: 'discrete',
        //     headerHover: this.showAptusExplanation(`Promedio normalizado Aptus en PDN ${this.pdnLatestMonth ? this.pdnLatestMonth : 'de un mes'}`),
        //     hover: true, hoverCheckFunction: this.pdnCheckFunction, hoverShowValue: this.pdnHoverValue,
        //     sortFunc: (a, b) => a - b,
        //     colors: this.colors,
        //     discreteColors: { [RANGE_KEYS.below]: '#e06666', [RANGE_KEYS.within]: '#fffdcc', [RANGE_KEYS.above]: '#93c47d' },
        //     getColorIndexValue: this.discretizePDNValues({ maxValue: 0.1, minValue: -0.3 }),
        //     warning: true,
        //     infoWarning: 'Si aplicas PDN Aptus, podrás ver tus resultados aquí',
        //     warningFunction: () => this.showPdnTeaser,
        //   }] : []),
        // ...(this.studentsHavePddColumn ?
        //   [{
        //     key: 'pddAverageValue', label: `PDD Aptus ${this.pddLatestMonth}`, format: 'numberWithOneDecimal',
        //     type: 'data-box', backgroundType: 'discrete',
        //     headerHover: this.showAptusExplanation(`Promedio normalizado Aptus en PDD ${this.pddLatestMonth}`),
        //     hover: true, hoverCheckFunction: this.pddCheckFunction, hoverShowValue: this.pddHoverValue,
        //     sortFunc: (a, b) => a - b,
        //     colors: this.colors,
        //     discreteColors: { [RANGE_KEYS.below]: '#e06666', [RANGE_KEYS.within]: '#fffdcc', [RANGE_KEYS.above]: '#93c47d' },
        //     getColorIndexValue: this.discretizePDDValues({ maxValue: 0.1, minValue: -0.3 }),
        //   }] : []),
        // ...(this.studentsHaveMdaColumn ?
        //   [{
        //     key: 'mdaAverageValue', label: `MDA Aptus ${this.mdaLatestMonth}`, format: 'numberWithOneDecimal',
        //     type: 'data-box', backgroundType: 'discrete',
        //     headerHover: this.showAptusExplanation(`Promedio normalizado Aptus en MDA ${this.mdaLatestMonth}`),
        //     hover: true, hoverCheckFunction: this.mdaCheckFunction, hoverShowValue: this.mdaHoverValue,
        //     sortFunc: (a, b) => a - b,
        //     colors: this.colors,
        //     discreteColors: { [RANGE_KEYS.below]: '#e06666', [RANGE_KEYS.within]: '#fffdcc', [RANGE_KEYS.above]: '#93c47d' },
        //     getColorIndexValue: this.discretizeMDAValues({ maxValue: 0.1, minValue: -0.3 }),
        //   }] : []),
        ...(this.studentsHaveReadingQualityColumn ?
          [{
            key: 'readingQualityShortName', label: `Calidad lectora ${this.readingQualityLastMonth}`,
            type: 'data-box', backgroundType: 'discrete',
            sortFunc: (a, b) => a - b, sortKey: 'readingQualityValue',
            colors: this.colors,
            discreteColors: { [RANGE_KEYS.below]: '#e06666', [RANGE_KEYS.within]: '#fffdcc', [RANGE_KEYS.above]: '#93c47d' },
            getColorIndexValue: this.discretizeReadingQualityValues({ maxValue: 1.5, minValue: -1.5, key: 'readingQualityNormValue' }),
            hover: true, hoverCheckFunction: this.readingQualityCheckFunction, hoverShowValue: this.readingQualityHoverValue,
          }] : []),
        ...(this.groupHasRepetition ?
          [{
            key: 'repetition', label: 'Repitente',
            type: 'data-box', icon: 'warning', sortFunc: (a, b) => a - b, colors: [RED_COLOR],
          }] : []),
        ...(this.showRepetitionRisk ?
          [{
            key: 'repetitionRiskBoolean', label: this.repetitionRiskLabel, headerHover: this.repetitionRiskDecree67Tooltip,
            type: 'data-box', backgroundType: 'discrete', icon: 'warning',
            warning: true, warningFunction: this.repetitionWarningFunction, infoWarning: this.repetitionInfoWarning,
            sortFunc: (a, b) => a - b, sortKey: 'repetition_risk', orderArray: REPETITION_RISK_ORDER_ARRAY,
            discreteColors: { [REPETITION_RISK_KEYS.grades]: REPETITION_RISK_GRADES_COLOR, [REPETITION_RISK_KEYS.absenteeism]: REPETITION_RISK_ABSENTEEISM_COLOR },
            getColorIndexValue: this.discretizeRepititionRisk,
            hover: true, hoverCheckFunction: this.repetitionRiskCheckFunction, hoverShowValue: this.repetitionRiskValue,
          }] : []),
        ...(this.groupHasPsicometrix ?
          [{
            key: 'psicometrix', norm: 'psicometrix', label: `${this.psicometrixColumnLabel}`, format: 'percentage',
            ...this.psicometrixMinMaxValues,
            type: 'data-box',
            headerHover: 'Resultado de prueba Psicometrix', hover: true, hoverShowValue: this.psicometrixTooltip, hoverCheckFunction: this.psicometrixCheckFunction,
            colors: this.colors, sortFunc: (a, b) => a - b,
          }] : []),
        ...(this.groupHasHistoricalRepetition ?
          [{
            key: 'historicalRepetitionBoolean', label: 'Repitencia histórica',
            type: 'data-box', icon: 'warning',
            sortFunc: (a, b) => a - b, colors: [RED_COLOR],
          }] : []),
        ...(this.groupHasPIE ?
          [{
            key: 'pieValue', label: 'PIE',
            type: 'data-box', backgroundType: 'discrete', icon: 'check',
            sortFunc: (a, b) => a - b,
            discreteColors: { 'permanent': PIE_PERMANENT_COLOR, 'temporary': PIE_TEMPORARY_COLOR },
            getColorIndexValue: this.discretizePIEValue,
            hover: true, hoverCheckFunction: this.pieCheckFunction, hoverShowValue: this.pieDisplayValue,
          }] : []),
        ...(this.groupHasPreferential ?
          [{
            key: 'preferential', label: 'Preferente', type: 'data-box', icon: 'check',
            sortFunc: (a, b) => a - b, colors: [PREFERENTIAL_COLOR],
          }] : []),
        ...(this.groupHasPriority ?
          [{
            key: 'priority', label: 'Prioritario', type: 'data-box', icon: 'check',
            sortFunc: (a, b) => a - b, colors: [PRIORITY_COLOR],
          }] : []),
        ...(true ? 
          [{
            key: 'negativos', label: 'Registros negativos', type: 'data-box', colors: [RED_BEHAVIOR_COLOR],
          }] : []),
        ...(true ? 
          [{
            key: 'neutros', label: 'Registros neutros', type: 'data-box', colors: [YELLOW_BEHAVIOR_COLOR],
          }] : []),
        ...(true ? 
          [{
            key: 'positivos', label: 'Registros positivos', type: 'data-box', colors: [GREEN_BEHAVIOR_COLOR],
          }] : [])
      ];
    },
    surveyColumns() {
      if(this.surveyAlerts.length) {
        return this.surveyAlerts.map((surveyAlert, index) => ({
          key: surveyAlert.key,
          norm: `${surveyAlert.key}NormValue`,
          label: surveyAlert.short_name,
          headerHover:
            `Pulso Escolar\n${surveyAlert.label}\nTomada en ${MONTHS[surveyAlert.month - 1]} ${surveyAlert.year}`,
          type: 'data-box',
          format: 'number',
          sortFunc: (a, b) => a - b,
          colors: [WHITE_COLOR, SURVEY_COLORS[index]],
          hover: true,
          hoverCheckFunction: r => r[surveyAlert.key],
          hoverShowValue: r => (
            r[`${surveyAlert.key}Alerts`].map(a => `<span> ${a} </span>`).join('<br/>')
          ),
        }))
       } else return [];
    },
    showPdnTeaser() {
      return this.rows.every(row => row.pdnAverageValue === null);
    },
    currentPage: {
      get() {
        return parseInt(this.$route.query.page, 10) || 1;
      },
      set(value) {
        this.$router.replace(
          { ...this.$route, query: { ...this.$route.query, page: value } },
          this.changePageCallback,
        );
      },
    },
    rows() {
      let students = this.students.slice();
      const repetitionRisksHashedIndexes = orderedArrayByIndexToHash(REPETITION_RISK_ORDER_ARRAY);
      
      if (this.students.length > 0) {
        students = students.map(e => ({
          ...e,
          repetitionRiskBoolean: !!e.repetition_risk,
          historicalRepetitionBoolean: e.historical_repetition_boolean,
          chronicAbsenteeism: e.chronic_absenteeism === null ? false : e.chronic_absenteeism,
          finalGradeValue: e.final_grade.value || e.final_grade.alternative_text,
          onSiteAttendanceValue: (e.on_site_attendance && e.on_site_attendance.value) || 0,
          attendanceValue: e.attendance.value,
          attendanceNormValue: e.attendance.norm_value,
          emnAverageValue: e.emn_average.value,
          emnAverageNormValue: e.emn_average.norm_value,
          readingQualityValue: e.reading_quality.value,
          readingQualityNormValue: e.reading_quality.norm_value,
          readingQualityName: e.reading_quality_name,
          readingQualityShortName: QUALITY_NAME_MAPPINGS[e.reading_quality.value],
          pdnAverageValue: e.pdn_average.value,
          pdnAverageNormValue: e.pdn_average.norm_value,
          pddAverageValue: e.pdd_average.value,
          pddAverageNormValue: e.pdd_average.norm_value,
          mdaAverageValue: e.mda_average.value,
          mdaAverageNormValue: e.mda_average.norm_value,
          //pulsoAlertsValue: e.pulso_alerts.length,
          //pulsoAlertsTooltip: e.pulso_alerts.join('<br/>'),
          tooltip: this.studentData(e),
          pieValue: this.pieFormattedValue(e),
          repetitionRiskTooltip: this.displayRepetitionRiskTooltip(e),
          psicometrix: e.psicometrix_info && e.psicometrix_info.score,
          psicometrixTooltip: e.psicometrix_info && this.psicometrixTooltip(e.psicometrix_info),
          ...(
            this.surveyAlerts.length ? Object.fromEntries(
              this.surveyAlerts.map(surveyAlert => (
                [
                  [surveyAlert.key, e.survey_alerts[surveyAlert.survey_type].value],
                  [`${surveyAlert.key}Alerts`, e.survey_alerts[surveyAlert.survey_type].alerts],
                  [`${surveyAlert.key}NormValue`, e.survey_alerts[surveyAlert.survey_type].norm_value],
                ]
              )).flat(1)
            ) : {}
          ),
        }));
        return students.sort((a, b) =>
          (this.groupHasRepetition ? b.repetition - a.repetition : repetitionRisksHashedIndexes[b.repetition_risk] - repetitionRisksHashedIndexes[a.repetition_risk]) ||
          b.chronicAbsenteeism - a.chronicAbsenteeism ||
          a.finalGradeValue - b.finalGradeValue,
        );
      } else return [];
    },
    studentsHaveReadingQualityColumn() {
      let data = [];
      if(Array.isArray(this.students)) {
        data = this.summaryInfo.reading_quality_latest_month && this.students.some(student => student.reading_quality.norm_value);
      }
      // return this.summaryInfo.reading_quality_latest_month && this.students.some(student => student.reading_quality.norm_value);
      return data;
    },
    studentsHaveEmnColumn() {
      return this.summaryInfo.emn_latest_month && this.students.some(student => student.emn_average.value);
    },
    studentsHavePulsoColumn() {
      return this.students.some(student => student.pulso_alerts.length > 0);
    },
    readingQualityLastMonth() {
      const { reading_quality_latest_month: latestMonth } = this.summaryInfo;
      return latestMonth && (MONTHS[latestMonth - 1] || TERM_NAMES[latestMonth]);
    },
    emnLatestMonth() {
      return this.summaryInfo.emn_latest_month && MONTHS[this.summaryInfo.emn_latest_month - 1];
    },
    studentsHavePdnColumn() {
      return this.summaryInfo.pdn_latest_month && this.students.some(student => student.pdn_average.value);
    },
    studentsHavePddColumn() {
      return this.summaryInfo.pdd_latest_month && this.students.some(student => student.pdd_average.value);
    },
    studentsHaveMdaColumn() {
      return this.summaryInfo.mda_latest_month && this.students.some(student => student.mda_average.value);
    },
    groupHasPIE() {
      return this.summaryInfo.pie_count > 0;
    },
    groupHasPriority() {
      return this.summaryInfo.priority_count > 0;
    },
    groupHasPreferential() {
      return this.summaryInfo.preferential_count > 0;
    },
    groupHasRepetition() {
      return this.summaryInfo.repetition_count !== null;
    },
    groupHasHistoricalRepetition() {
      return this.summaryInfo.historical_repetition_count > 0;
    },
    hasAdditionalPie() {
      return this.summaryInfo.pie_additional !== null;
    },
    groupHasPsicometrix() {
      return this.students.some((e) => e.psicometrix_info !== null);
    },
    pdnLatestMonth() {
      return this.summaryInfo.pdn_latest_month && MONTHS[this.summaryInfo.pdn_latest_month - 1];
    },
    pddLatestMonth() {
      return this.summaryInfo.pdd_latest_month && MONTHS[this.summaryInfo.pdd_latest_month - 1];
    },
    mdaLatestMonth() {
      return this.summaryInfo.mda_latest_month && MONTHS[this.summaryInfo.mda_latest_month - 1];
    },
    psicometrixTestType() {
      return this.summaryInfo.psicometrix_test_type;
    },
    psicometrixColumnLabel() {
      switch (this.psicometrixTestType) {
      case 'pv':
        return 'Orientación Pre-Vocacional';
      case 'ov':
        return 'Orientación Vocacional';
      default:
        return null;
      }
    },
    psicometrixMinMaxValues() {
      switch (this.psicometrixTestType) {
      case 'pv':
        return { minValue: 65, maxValue: 100 };
      case 'ov':
        return { minValue: 50, maxValue: 90 };
      default:
        return null;
      }
    },
    lowReadingQualityValue() {
      return this.summaryInfo.low_reading_quality_data && this.summaryInfo.low_reading_quality_data.value;
    },
    hiddenColumnsCount() {
      const columnsCount = HIDDEN_COLUMNS_COUNT - !this.groupHasPIE - !this.groupHasPriority - !this.groupHasPreferential;
      return this.studentsHaveEmnColumn ||
        this.studentsHavePdnColumn ||
        this.studentsHavePddColumn ||
        this.studentsHaveMdaColumn ||
        this.studentsHaveReadingQualityColumn ?
        columnsCount :
        0;
    },
    headTeacher() {
      return this.summaryInfo.head_teacher;
    },
    repetitionRiskPresent() {
      return this.rows.some(student => student.repetition_risk !== null);
    },
  },
  watch: {
    options: {
      handler: 'fetchInfo',
      immediate: true,
    },
  },
  methods: {
    async fetchInfo() {
      await this.fetchSummary();
    },
    async fetchSummary() {
      this.error = false;
      this.loadTable = false;
      this.summaryBoolean = false;
      this.surveyIndicatorsBolean = false;

      try {
        const { body } = await GroupStudentsApi.getGroupSummary(this.options); 
        this.summaryLoading = false; 
        this.summaryInfo = {};
        this.rowsPaginated = this.rows.slice();

        if(Object.keys(body).length > 0 && body !== null) {
          this.summaryInfo = body.summary || {};

          if(body.students) {
            this.students = body.students;
          }
          if(Object.entries(this.summaryInfo).length !== 0) {
            this.summaryBoolean = true;
          }
          if(this.surveyIndicators.length) {
            this.surveyIndicatorsBolean = true;
          }

          setTimeout(() => {
            this.loadTable = true;
            this.loadPager = true;
          }, 900);
        }
      } catch (_) {
        this.error = true;
        this.summaryLoading = false;
      } finally {
        this.summaryLoading = false;
        if(!this.loadTable && this.rows.length > 0) {
          setTimeout(() => {
            this.loadTable = true;
            this.loadPager = true;
          }, 1500);
        }
      }
    },
    showAptusExplanation(message) {
      // eslint-disable-next-line no-magic-numbers
      return this.chosenYear === 2020 ? message.concat(`\n ${this.aptusExplanationMessage}`) : message;
    },
    percentage(value) {
      return (value / this.summaryInfo.student_count) || 0;
    },
    year() {
      return this.chosenYear;
    },
    handleClick({ id }) {
      this.$router.push({
        name: 'studentReport',
        params: { student: id },
      });
    },
    repetitionWarningFunction(row) {
      return row.repetition_risk === null;
    },
    warningFunction(row) {
      return [5, 4, 2, 1].includes(row.repetition_risk) && row.chronic_absenteeism; // eslint-disable-line no-magic-numbers
    },
    repetitionRiskValue(row) {
      return row.repetitionRiskTooltip;
    },
    readingQualityHoverValue(row) {
      return row.readingQualityName;
    },
    pdnHoverValue() {
      return `Promedio Normalizado de las pruebas PDN de todas las asignaturas rendidas en ${this.pdnLatestMonth} de ${this.chosenYear}`;
    },
    pddHoverValue() {
      return `Promedio Normalizado de las pruebas PDD de todas las asignaturas rendidas en ${this.pddLatestMonth} de ${this.chosenYear}`;
    },
    mdaHoverValue() {
      return `Promedio Normalizado de las pruebas MDA de todas las asignaturas rendidas en ${this.mdaLatestMonth} de ${this.chosenYear}`;
    },
    emnHoverValue() {
      return `Promedio Normalizado de las pruebas EMN de todas las asignaturas rendidas en ${this.emnLatestMonth} de ${this.chosenYear}`;
    },
    displayRepetitionRiskTooltip(student) {
      return `${repetitionRiskValueToMessage(student.repetition_risk)}`;
    },
    psicometrixTooltip(row) {
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      return row.psicometrix_info &&
        `<b>${this.psicometrixTestType === 'pv' ? 'Área profesional de mayor interés' : 'Carrera de preferencia'}</b><br>
        ${row.psicometrix_info.description}<br>
        Compatibilidad: ${row.psicometrix_info.score}%<br>
        Fecha medición: ${new Date(row.psicometrix_info.date).toLocaleString('es-ES', dateOptions)}  <br>`;
    },
    pulsoAlertsTooltip(row) {
      return row.pulso_alerts.join('<br/>');
    },
    pieCheckFunction(row) {
      return row.pie_boolean;
    },
    repetitionRiskCheckFunction(row) {
      return row.repetitionRiskBoolean;
    },
    readingQualityCheckFunction(row) {
      return row.readingQualityNormValue !== null;
    },
    pdnCheckFunction(row) {
      return row.pdnAverageValue !== null;
    },
    pddCheckFunction(row) {
      return row.pddAverageValue !== null;
    },
    mdaCheckFunction(row) {
      return row.mdaAverageValue !== null;
    },
    emnCheckFunction(row) {
      return row.emnAverageValue !== null;
    },
    psicometrixCheckFunction(row) {
      return row.psicometrix !== null;
    },
    pulsoCheckFunction(row) {
      return row.pulsoAlertsValue !== null;
    },
    pieDisplayValue(row) {
      if (row.pie === '') return '';
      return row.pie_type;
    },
    pieFormattedValue(row) {
      if (!row.pie_boolean) return null;
      return row.pie && row.pie.includes('Permanente') ? 1 : 2; // eslint-disable-line no-magic-numbers
    },
    readingQualityCheck(row) {
      return row.reading_quality.value !== null;
    },
    readingQualityDisplayValue(row) {
      if (row.reading_quality_name === '') return '';
      return `${row.readingQualityShortName} : ${row.reading_quality_name}`;
    },
    discretizeRepititionRisk(row) {
      if ([5, 4, 2, 1].includes(row.repetition_risk)) { // eslint-disable-line no-magic-numbers
        return REPETITION_RISK_KEYS.grades;
      } else if (row.repetition_risk === 3) { // eslint-disable-line no-magic-numbers
        return REPETITION_RISK_KEYS.absenteeism;
      }
      return '';
    },
    discretizePIEValue(row) {
      return row.pie.includes('Permanente') ? 'permanent' : 'temporary';
    },
    discretizeEMNValues({ maxValue, minValue }) {
      return function (row) {
        const value = row.emnAverageNormValue || row.emnAverageValue;
        if (value <= minValue) return RANGE_KEYS.below;
        else if (value > maxValue) return RANGE_KEYS.above;
        return RANGE_KEYS.within;
      };
    },
    discretizePDNValues({ maxValue, minValue }) {
      return function (row) {
        const value = row.pdnAverageNormValue || row.pdnAverageValue;
        if (value <= minValue) return RANGE_KEYS.below;
        else if (value > maxValue) return RANGE_KEYS.above;
        return RANGE_KEYS.within;
      };
    },
    discretizePDDValues({ maxValue, minValue }) {
      return function (row) {
        const value = row.pddAverageNormValue || row.pddAverageValue;
        if (value <= minValue) return RANGE_KEYS.below;
        else if (value > maxValue) return RANGE_KEYS.above;
        return RANGE_KEYS.within;
      };
    },
    discretizeMDAValues({ maxValue, minValue }) {
      return function (row) {
        const value = row.mdaAverageNormValue || row.mdaAverageValue;
        if (value <= minValue) return RANGE_KEYS.below;
        else if (value > maxValue) return RANGE_KEYS.above;
        return RANGE_KEYS.within;
      };
    },
    discretizeReadingQualityValues({ maxValue, minValue, key }) {
      return function (row) {
        const value = row[key];
        if (value <= minValue) return RANGE_KEYS.below;
        else if (value >= maxValue) return RANGE_KEYS.above;
        return RANGE_KEYS.within;
      };
    },
    onItems(info) {
      this.loadNewData = false;
      this.rowsPaginated = [];
      this.numberRow = info.start + 1;
      setTimeout(() => {
        this.rowsPaginated = JSON.parse(JSON.stringify(info.data));
        this.loadNewData = true;
      }, 400);
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only print {
    break-after: page;
    flex-direction: column;
  }
}

.box {
  display: flex;
  flex-direction: column;
  background-color: $sidebar-background;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-weight: 200;
  margin-top: 10px;

  &-left {
    width: 290px;
    margin-right: 20px;
  }

  &-right {
    flex-grow: 100;
    flex-basis: calc(100% - 313px);
  }
}

.teacher-name {
  font-weight: bold;
  font-size: 18px;
}

.title {
  font-weight: bold;
  font-size: 22px;
}

.sub-title {
  margin-top: 5px;
  margin-bottom: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
  padding: 8px 0;
}

.indicator {
  width: 25%;
  line-height: 90%;
  @media only screen and (max-width: $mobile-breakpoint) {
    width: 50%;
  }
}

.value {
  width: 15%;
  text-align: center;
}

.bar {
  flex-grow: 100;
  align-items: center;
}

table.course-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $sidebar-background;
      }
    }
    tr:hover {
      td {
        background-color: #f9f9fd;
      }
    }
  }
}

div.course-report-header-cell {
  height: 42px;
}
div.course-report-cell {
  font-size: 14px;
  height: 40px;
}
.loading {
  margin-top: 30px;
}
.survey-alerts {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &-data-box {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
</style>
