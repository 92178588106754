<template>
  <div :class="[$style.column]">
    <div v-if="hasSurveyAlerts" :class="$style.greyBackground">
      <hr>
      <h2>Alertas Encuestas Pulso Escolar</h2>
      <div :class="$style.row">
        <div v-for="([surveyType, data], surveyTypeIndex) in Object.entries(surveyAlerts)"
          :class="$style.surveyAlertsColumn" :key="`${surveyType}-${surveyTypeIndex}`">
          <span :style="alertStyle(surveyTypeIndex)" :class="['info__label', $style.alertTypeLabel]">
            {{ surveyTypeLabel(data) }}
          </span>
          <div v-for="(alert, alertIndex) in data.alerts" :class="$style.temporalInfo" :key="alertIndex">
            <i :class="['material-icons', $style.surveyAlertLabel]" :style="alertStyle(surveyTypeIndex)">
              label_important </i>
            <span> {{ alert }} </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasPulsoAlerts" :class="$style.greyBackground">
      <hr>
      <h2>Alertas Encuestas Pulso Escolar</h2>
      <div :class="$style.row">
        <div v-for="(alert, alertIndex) in pulsoAlerts" :class="$style.temporalInfo" :key="alertIndex">
          <i :class="['material-icons', $style.surveyAlertLabel]"> label_important </i>
          <span> {{ alert }} </span>
        </div>
      </div>
    </div>
    <div :class="$style.upperBox">
      <hr>
      <h2>Asistencia</h2>
      <div :class="$style.row">
        <result-box v-if="yearlyAttendance" :value="yearlyAttendance" :relative-value="yearlyRelativeAttendance"
          value-filter-key="percentageWithOneDecimal" title="PROMEDIO ESTUDIANTE" />
        <div :class="[$style.rowNoWrap]">
          <div>
            <div :class="$style.fullWidth">
              <span class="info__label">Evolución asistencia últimos años</span>
            </div>
            <historic-chart :periods="years" :columns="attendanceEvolutionsColumns" :view-window="attendanceChartFrame"
              :v-axis-format="attendanceChartFormat" :rows="attendanceEvolutionRows" :height="200" :width="300"
              :ticks="attendanceTicks" :line-width="1" :point-size="5" label-type="string" />
          </div>
          <div :class="[$style.column]">
            <div v-for="(column, index) in attendanceEvolutionsColumns" :key="index" class="info__column">
              <div :style="{ borderColor: chartColors[index] }" class="info__color" />
              <div class="info__column-name">{{ column }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.greyBackground">
      <hr>
      <h2>Calificaciones</h2>
      <div :class="$style.row">
        <result-box v-if="finalGrade" :value="finalGrade.student_value" :relative-value="relativeGrade(finalGrade)"
          metric-type="pts" title="PROMEDIO ESTUDIANTE" />
        <div :class="[$style.row, $style.fixedSize]">
          <div>
            <div :class="$style.fullWidth">
              <span class="info__label">Evolución calificaciones últimos años</span>
            </div>
            <historic-chart :periods="years" :columns="studentAndGroupColumns" :view-window="gradeChartFrame"
              :v-axis-format="gradeChartFormat" :rows="evolutionGrades" :line-width="1" :ticks="gradeTicks"
              :height="200" :width="300" :point-size="5" background-color="#FBFBFE" label-type="string" />
          </div>
          <div :class="[$style.column]">
            <div v-for="(column, index) in studentAndGroupColumns" :key="index" class="info__column">
              <div :style="{ borderColor: chartColors[index] }" class="info__color" />
              <div class="info__column-name">{{ column }}</div>
            </div>
          </div>
        </div>
        <div v-if="hasGrades">
          <span class="info__label">Promedio por asignatura</span>
          <table :class="$style.small" border="1" frame="void" rules="rows">
            <tr v-for="(grade, index) in shownSubjectGrades" :key="index">
              <td> {{ grade.label }} </td>
              <td :style="{'color': gradeColor(grade.student_value)}"><b>{{ grade.student_value | numberWithOneDecimal
                  }}</b></td>
              <td v-if="isNumericGrade(grade) && relativeGrade(grade) !== 0">
                <i :style="{'color': iconColor(grade)}" :class="['material-icons', $style.icon]">
                  {{ icon(grade) }}
                </i>
                <b>{{ relativeGradeShowValue(grade) | numberWithOneDecimal }}</b> pts <b>{{ relativeText(grade) }}</b>
                su curso
              </td>
              <td v-else-if="relativeGrade(grade) === 0">
                <b :class="$style.padded">Igual</b> al promedio del curso
              </td>
              <td v-else-if="!isTextGrade(grade)">
                No hay información del curso
              </td>
            </tr>
            <div v-if="showButton" :class="$style.visibilityToggler" @click="toggleShow()">
              <i :class="['material-icons', $style.icon]">
                {{ gradeIcon }}
              </i>
              <b>{{ showGrades ? 'Cerrar' : 'Ver todas' }}</b>
            </div>
          </table>
        </div>
      </div>
    </div>

    <div :class="$style.greyBackground">
      <hr>
      <h2>Conducta</h2>
      <div :class="$style.row">
        <div :class="[$style.fixedSize, $style.conducta]">
          <template v-if="loadGraph">
            <div :class="$style['graph-container']">
              <line-chart ref="lineGraph" :chart-data="dataReport" :chart-options="lineOptions" />
            </div>
          </template>
          <div :class="[$style.column]">

            <div class="info__column">
              <div style="border-color: #FF4F56;" class="info__color" />
              <div class="info__column-name">Positivos</div>
            </div>

            <div class="info__column">
              <div style="border-color: #FFCF54;" class="info__color" />
              <div class="info__column-name">Neutros</div>
            </div>

            <div class="info__column">
              <div style="border-color: #5AAC27;" class="info__color" />
              <div class="info__column-name">Negativos</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasReadingQualityResults" :class="hasAptusResults ? $style.greyBackground : $style.upperBox">
      <hr>
      <h2>Calidad Lectora<span v-if="showReadingQualityExplanation">*</span></h2>
      <div :class="$style.row">
        <result-box v-if="readingQuality" :object-value="readingQuality"
          :relative-value="readingQuality && readingQuality.normValue" :color-picker="readingQualityColorPicker"
          custom-footer=" " title="CALIDAD LECTORA ESTUDIANTE" />
        <div>
          <div :class="$style.fullWidth">
            <span class="info__label">Evolución calidad lectora últimos años</span>
          </div>
          <historic-chart :columns="readingQualityColumns" :view-window="readingQualityChartFrame"
            :rows="readingQualityEvolutionRows" :line-width="1" :ticks="readingQualityTicks" :height="200" :width="300"
            :point-size="5" :background-color="hasAptusResults ? 'FBFBFE' : ''" :v-axis-text="false" label-type="string"
            custom-tooltip />
        </div>
        <div :class="[$style.column]">
          <div v-for="(column, index) in readingQualityColumns" :key="index" class="info__column">
            <div :style="{ borderColor: chartColors[index] }" class="info__color" />
            <div class="info__column-name">{{ column }}</div>
          </div>
        </div>
        <explanation-message v-if="showReadingQualityExplanation"
          :explanation-message="readingQualityExplanationMessage" />
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
import PermissionMixin from '../../mixins/permission';
import HistoricChart from '../../components/historic-chart.vue';
import ResultBox from '../../components/utils/result-box.vue';
import {
  INCREASE_COLOR,
  DECREASE_COLOR,
  APPROVED_COLOR,
  FAIL_COLOR,
  MAIN_TEXT_COLOR,
  SEMAPHORE_COLORS,
  TEXT_GRADE_COLOR,
  SURVEY_COLORS,
} from '../../utils/style-variables';
import GRAPH_COLORS from '../../utils/graph-colors';
import { APPROVAL_LIMIT_GRADE } from '../../utils/constants';
import { MONTHS, TERM_NAMES } from '../../utils/months';
import ExplanationMessage from '../../components/explanation-message.vue';
import LineChart from '../../components/line-gorup-chart.vue';

export default {
  name: 'StudentSummaryView',
  components: {
    HistoricChart,
    ResultBox,
    ExplanationMessage,
    LineChart
  },
  mixins: [PermissionMixin],
  props: {
    grades: {
      type: Array,
      default: () => [],
    },
    attendances: {
      type: Array,
      default: () => [],
    },
    years: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
      default: 0,
    },
    vAxisFormat: {
      type: String,
      default: '',
    },
    finalGrades: {
      type: Array,
      default: () => [],
    },
    readingQualityResults: {
      type: Array,
      default: () => [],
    },
    hasAptusResults: {
      type: Boolean,
      default: false,
    },
    aptusResults: {
      type: Object,
      default: () => ({ 'student': [], 'group': [] }),
    },
    aptusColorPicker: {
      type: Function,
      default: () => MAIN_TEXT_COLOR,
    },
    surveyAlerts: {
      type: Object,
      default: () => ({}),
    },
    pulsoAlerts: {
      type: Array,
      default: () => [],
    },
    showAptusExplanation: {
      type: Boolean,
      default: false,
    },
    aptusExplanationMessage: {
      type: String,
      default: null,
    },
    showReadingQualityExplanation: {
      type: Boolean,
      default: false,
    },
    readingQualityExplanationMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loadGraph: true, 
      attendanceChartFormat: '#\'%\'',
      gradeChartFormat: '0',
      aptusChartFormat: '0.0',
      chartColors: GRAPH_COLORS,
      chartFormatter: { groupingSymbol: '', fractionDigits: 1, suffix: '%' },
      showGrades: false,
      attendanceEvolutionsColumns: ['Alumno', 'Generación'],
      studentAndGroupColumns: ['Alumno', 'Curso'],
      readingQualityColumns: ['Alumno', 'Esperado'],
      dataReport: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
               {
                  label: 'Negativos',
                  fill: false,
                  backgroundColor: '#FF5454', // Rojo
                  borderColor: '#FF5454', // Rojo
                  borderWidth: 3,
                  data: [70, 65, 68, 70, 70, 70, 65, 70, 75, 65, 70, 80],
                  stack: 'Stack 0',
              },
              {
                  label: 'Neutros',
                  fill: false,
                  backgroundColor: '#FFCF54', // Naranja
                  borderColor: '#FFCF54', // Naranja
                  borderWidth: 3,
                  data: [28, 35, 40, 25, 20, 28, 48, 40, 40, 20,10,40],
                  stack: 'Stack 0',
              },
              {
                  label: 'Positivos',
                  fill: false,
                  backgroundColor: '#77BD25', // Verde
                  borderColor: '#77BD25', // Verde
                  borderWidth: 3,
                  data: [60, 50, 55, 50, 40, 50, 55, 60, 70, 40,40,45],
                  stack: 'Stack 0',
              },
          ]
      },
      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
        padding: {
          top: 0,
          },
        },
        tooltips: {
          enabled: true,
          displayColors: true,
          callbacks: {
            mode: 'x',
          },
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              borderDash: ['8', '4'],
              color: '#DCDCDC',
              fill: false,
            },
          }],
        },
        legend: {
          position: 'bottom',
          display: false,
          labels: {
            usePointStyle: true,
          },
          onClick: (e) => e.stopPropagation(),
        },
        hover: {
          animationDuration: 1,
        },
        elements: {
            line: {
              tension: 0,
              backgroundColor: 'transparent', 
            },
            point: {
              radius: 2,
              hitRadius: 10,
              hoverRadius: 8,
              backgroundColor: 'transparent',
            },
        },
      },
    };
  },
  computed: {
    hasReadingQualityResults() {
      return Array.isArray(this.readingQualityResults) && this.readingQualityResults.length;
    },
    gradeIcon() {
      if (this.showGrades) return 'minimize';
      return 'add';
    },
    finalGrade() {
      return this.finalGrades.find(grade => grade.year === this.year);
    },
    evolutionAptusResults() {
      return this.aptusResults.student && this.aptusResults.student.slice().sort(this.sortAptusResults);
    },
    evolutionAptusGroupResults() {
      return this.aptusResults.group && this.aptusResults.group.slice().sort(this.sortAptusResults);
    },
    currentAptusResult() {
      return this.evolutionAptusResults.slice(-1)[0] && this.evolutionAptusResults.slice(-1)[0].value;
    },
    relativeAptusResult() {
      return this.evolutionAptusGroupResults.slice(-1)[0] && (this.currentAptusResult - this.evolutionAptusGroupResults.slice(-1)[0].value);
    },
    aptusRange() {
      const { student, group } = this.aptusResults;
      return student && group && student.concat(group).reduce(
        (acc, curr) => ({ min: Math.min(acc.min, curr.value), max: Math.max(acc.max, curr.value) }),
        { min: -1.0, max: 1.0 }
      ) || { min: -1.0, max: 1.0 };
    },
    aptusEvolutionRows() {
      const dateFormatOptions = { year: 'numeric', month: 'short' };
      return this.evolutionAptusResults && this.evolutionAptusResults.map(
        (e, i) => [
          `${e.indicator_label.split(' ')[0]}, ${new Date(e.year, e.month, 0).toLocaleString('es-ES', dateFormatOptions)}`,
          e.value,
          this.evolutionAptusGroupResults[i].value,
        ]
      );
    },
    aptusTicks() {
      const step = 0.5;
      const scaled = { min: Math.floor(this.aptusRange.min / step), max: Math.ceil(this.aptusRange.max / step) };
      const nTicks = scaled.max - scaled.min + 1;
      const ticks = [...Array(nTicks).keys()].map(i => ((i + scaled.min) * step));
      return ticks;
    },
    aptusChartFrame() {
      return { min: this.aptusTicks[0], max: this.aptusTicks.slice(-1)[0] };
    },
    gradeChartFrame() {
      const chartFrame = { min: 4, max: 7 };
      if (this.finalGrades.some(this.repetitionGrade)) chartFrame.min = 1;
      return chartFrame;
    },
    gradeTicks() {
      const frame = this.gradeChartFrame;
      const length = frame.max - frame.min + 1;
      return Array.from({ length }, (_, i) => frame.min + i);
    },
    readingQualityChartFrame() {
      return { min: 0, max: 4 };
    },
    readingQualityTicks() {
      const frame = this.readingQualityChartFrame;
      const length = frame.max - frame.min + 1;
      return Array.from({ length }, (_, i) => frame.min + i);
    },
    subjectGrades() {
      return this.grades.filter(g => g.year === this.year).sort((a, b) => a.student_value - b.student_value);
    },
    shownSubjectGrades() {
      return this.showGrades ? this.subjectGrades : this.subjectGrades.slice(0, 4); // eslint-disable-line no-magic-numbers
    },
    hasGrades() {
      return !!this.subjectGrades.length;
    },
    showButton() {
      return this.subjectGrades.length > 4; // eslint-disable-line no-magic-numbers
    },
    evolutionGrades() {
      return this.years.map(year => {
        const yearlyResult = this.finalGrades.find(result => result.year === year);
        return [
          year.toString(),
          yearlyResult && typeof yearlyResult.student_value === 'number' ?
            yearlyResult.student_value : null,
          yearlyResult && typeof yearlyResult.course_value === 'number' ?
            yearlyResult.course_value : null,
        ];
      });
    },
    attendanceChartFrame() {
      return { max: 100, min: this.attendanceEvolutionRows.some(r => [1, 2].some(i => r[i] < 50)) ? 0 : 50 }; // eslint-disable-line no-magic-numbers
    },
    attendanceTicks() {
      if (this.attendanceChartFrame.min === 50) return [50, 60, 70, 80, 90, 100]; // eslint-disable-line no-magic-numbers
      return [20, 40, 60, 80, 100]; // eslint-disable-line no-magic-numbers
    },
    yearlyRelativeAttendance() {
      const yearAttendance = this.attendances.find(attendance => attendance.year === this.year);
      return yearAttendance && yearAttendance.student_value && yearAttendance.course_value ? yearAttendance.student_value - yearAttendance.course_value : null;
    },
    yearlyAttendance() {
      const yearAttendance = this.attendances.find(attendance => attendance.year === this.year);
      return yearAttendance ? yearAttendance.student_value : null;
    },
    attendanceEvolutionRows() {
      return this.years.map(year => {
        const yearlyResult = this.attendances.find(result => result.year === year);
        return [year.toString(), yearlyResult ? yearlyResult.student_value : null, yearlyResult ? yearlyResult.group_value : null];
      });
    },
    readingQuality() {
      const lastResult = this.sortedReadingQualityResults && this.sortedReadingQualityResults.slice(-1)[0];
      return lastResult ? {
        normValue: lastResult.student.value - lastResult.course.value,
        label: lastResult.student.alternative_text,
      } : null;
    },
    sortedReadingQualityResults() {
      return this.readingQualityResults.slice().sort((a, b) => (a.year - b.year || a.month - b.month));
    },
    readingQualityEvolutionRows() {
      return this.sortedReadingQualityResults && this.sortedReadingQualityResults.map(res => (
        [
          `${MONTHS[res.month - 1] || TERM_NAMES[res.month]} ${res.year} `,
          res.student.value,
          res.student.alternative_text,
          res.course.value,
          res.course.alternative_text,
        ]
      ));
    },
    hasSurveyAlerts() {
      return Object.keys(this.surveyAlerts).length;
    },
    hasPulsoAlerts() {
      return this.pulsoAlerts.length;
    },
  },
  methods: {
    readingQualityColorPicker(value) {
      if (value > -1) {
        return SEMAPHORE_COLORS.high;
      } else if (value < -1) {
        return SEMAPHORE_COLORS.low;
      }
      return SEMAPHORE_COLORS.middle;
    },
    sortAptusResults(r1, r2) {
      if (r1.year === r2.year) {
        return (r1.month === r2.month ? - r1.indicator_key.localeCompare(r2.indicator_key) : r1.month - r2.month);
      }
      return r1.year - r2.year;
    },
    icon(grade) {
      const value = this.relativeGrade(grade);
      if (!value) return '';
      return value > 0 ? 'arrow_upward' : 'arrow_downward';
    },
    repetitionGrade(grade) {
      if (grade.student_value && grade.student_value < APPROVAL_LIMIT_GRADE) return true;
      if (grade.group_value && grade.group_result < APPROVAL_LIMIT_GRADE) return true;
      return false;
    },
    iconColor(grade) {
      const value = this.relativeGrade(grade);
      if (!value) return TEXT_GRADE_COLOR;
      return value > 0 ? INCREASE_COLOR : DECREASE_COLOR; // eslint-disable-line no-magic-numbers
    },
    gradeColor(value) {
      if (typeof value !== 'number') return TEXT_GRADE_COLOR;
      return value >= APPROVAL_LIMIT_GRADE ? APPROVED_COLOR : FAIL_COLOR;
    },
    toggleShow() {
      this.showGrades = !this.showGrades;
    },
    relativeText(grade) {
      const value = this.relativeGrade(grade);
      if (!value) return '';
      return value < 0 ? 'bajo' : 'sobre';
    },
    hasInfo(grade) {
      return grade.course_value && grade.student_value;
    },
    isTextGrade(grade) {
      return ['course_value', 'student_value'].some(key => (
        grade[key] && typeof grade[key] === 'string'
      ));
    },
    isNumericGrade(grade) {
      return ['course_value', 'student_value'].every(key => (
        grade[key] && typeof grade[key] === 'number'
      ));
    },
    relativeGrade(grade) {
      const value = this.isNumericGrade(grade) ? grade.student_value - grade.course_value : null;
      return value === null ? null : Math.round(value * 10) / 10; // eslint-disable-line no-magic-numbers
    },
    relativeGradeShowValue(grade) {
      return Math.abs(this.relativeGrade(grade));
    },
    alertStyle(surveyTypeIndex) {
      return { color: SURVEY_COLORS[surveyTypeIndex] };
    },
    surveyTypeLabel({ label, year, month }) {
      return `${label} (${MONTHS[month - 1]}-${year})`;
    },
  },
};

</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.column{
   display: flex;
   flex-direction: column;
   justify-content: center;
  .report {
    width: 100%;
    height: 200px;
  }

  td {
    padding: 8px 15px;
    font-size: 12px;
  }

  .grey-background{
    background-color: #FBFBFE;
    padding: 0 20px;
  }

  .upper-box{
    padding: 0 20px;
  }

  .small {
    line-height: 22px;
  }

  .icon {
    font-size: 12px !important;
    padding-top: 5px;
  }

  .padded {
    padding-left: 14px;
  }

  .visibility-toggler {
    cursor: pointer;
    font-size: 12px;
  }

  .fixed-size{
    height: 225px;

    @media only screen and (max-width: $mobile-breakpoint) {
      height: auto;
    }
  }

  .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .row-no-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-wrap: wrap;
    }
  }

  .full-width {
    width: 100%;
    text-align: center;
  }

  .conducta {
    display: flex;
  }


  .graph-container  {
    align-self: flex-end;
    margin: 0 auto;
    height: 200px;
    width: 400px;
    position: relative;
  }

  .survey-alerts-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 30%;
    padding-right: 10px;

    .alert-type-label {
      padding-bottom: 10px;
    }

    .survey-alert-label {
      font-size: 12px !important;
    }
  }
}
</style>
