<template>
  <paywall
    :permitted="isLoggedIn && hasGradesPermission"    
    :free="isFreeUser"
    restricted
    transparent>

    <rdr-results-header
      :show-school-selector="isLoggedIn"
      year-picker
      hide-school-picker
      show-school-picker
      show-school-year-picker
      show-planned-term-picker>
    </rdr-results-header>
    <router-view/>
    <tabs
      :tabs="bySchoolTabs"
      :tab-width="200"
      links/>
    <router-view/>
    <!-- <div class="content-filters">
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === null }" @click="toggleDatasetVisibility(null, true)">
        Todos
      </a>
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === 2 }" @click="toggleDatasetVisibility(2)">
        Positivos
      </a>
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === 0 }" @click="toggleDatasetVisibility(0)">
        Negativos
      </a>
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === 1 }" @click="toggleDatasetVisibility(1)">
        Neutros
      </a> -->
    <!-- </div> -->
    <div class="conten-filters">
      <div class="ttl-panel">Evolución de los registros por mes</div>
    </div>
    <div id="content-attendance-e" class="results-colormap">         
      <div v-if="loadGraph" class="contenGraficas-line-evolution">
        <div v-if="loading">
          <transition name="fade">
            <loading-overlay/>
          </transition>
        </div>
        <div v-else>
          <template v-if="loadGraph">
            <div class="content-justify-linechart">
              <line-chart
                ref="lineGraph"
                :chart-data="dataReport"
                :chart-options="lineOptions"
              />
            </div>
          </template>
           <div class="content-filters">
             <div class="contentlegen">
              <div class="legen all" :class="{ active_legen: activeTab === null }">
              </div>
              <span>Todos</span>
           </div>
              
            <div class="contentlegen">
              <div class="legen positivos" :class="{ active_legen: activeTab === 'Positivos' }">
              </div>
              <span>Positivos</span>
           </div>
           
           <div class="contentlegen">
              <div class="legen negativos" :class="{ active_legen: activeTab === 'Negativos' }">
              </div>
              <span>Negativos</span>
            </div>

            <div class="contentlegen">
              <div class="legen neutros" :class="{ active_legen: activeTab === 'Neutros' }">
              </div>
              <span>Neutros</span>
             </div>
            </div>
        </div>
      </div>
    </div> 
  </paywall>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import ResultsHeader from '../../../components/results/results-attendance-header.vue';
import Tabs from '../../../components/tabs.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';
import LineChart from '../../../components/line-gorup-chart.vue';

/* eslint-disable camelcase */

export default {
  name: 'coexistenceGPSchoolEvolucion',
  components: {
    //ColorMapFooter,
    'rdr-results-header': ResultsHeader,
    LineChart,
    'tabs': Tabs,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      loadGraph: true,
      activeTab: null,
      dataReport: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
               {
                  label: 'Negativos',
                  fill: false,
                  backgroundColor: '#FF5454', // Rojo
                  borderColor: '#FF5454', // Rojo
                  borderWidth: 3,
                  data: [70, 65, 68, 70, 70, 70, 65, 70, 75, 65, 70, 80],
                  stack: 'Stack 0',
              },
               {
                  label: 'Negativos',
                  fill: false,
                  backgroundColor: '#FF5454', // Rojo
                  borderColor: '#FF5454', // Rojo
                  borderWidth: 3,
                  data: [65, 59, 57, 60, 60, 65, 59, 80, 81, 56, 60, 60],
                  borderDash: [5, 5],
                  stack: 'Stack 0',
              },
              {
                  label: 'Neutros',
                  fill: false,
                  backgroundColor: '#FFCF54', // Naranja
                  borderColor: '#FFCF54', // Naranja
                  borderWidth: 3,
                  data: [28, 35, 40, 25, 20, 28, 48, 40, 40, 20,10,40],
                  stack: 'Stack 0',
              },
               {
                  label: 'Neutros',
                  fill: false,
                  backgroundColor: '#FFCF54', // Naranja
                  borderColor: '#FFCF54', // Naranja
                  borderWidth: 3,
                  data: [25, 30, 35, 20, 15, 22, 30, 20, 35, 15,6,30],
                  borderDash: [5, 5],
                  stack: 'Stack 0',
              },
              {
                  label: 'Positivos',
                  fill: false,
                  backgroundColor: '#77BD25', // Verde
                  borderColor: '#77BD25', // Verde
                  borderWidth: 3,
                  data: [60, 50, 55, 50, 40, 50, 55, 60, 70, 40,40,45],
                  stack: 'Stack 0',
              },
              {
                  label: 'Positivos',
                  fill: false,
                  backgroundColor: '#77BD25', // Verde
                  borderColor: '#77BD25', // Verde
                  borderWidth: 3,
                  data: [58, 48, 50, 48, 38, 48, 50, 58, 68, 38, 38, 40],
                  borderDash: [5, 5],
                  stack: 'Stack 0',
              },
          ]
      },
      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
        padding: {
          top: 0,
        },
      },
      tooltips: {
        enabled: true,
        displayColors: true,
        callbacks: {
          mode: 'x',
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            borderDash: ['8', '4'],
            color: '#DCDCDC',
            fill: false,
          },
        }],
      },
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          usePointStyle: true,
        },
        onClick: (e) => e.stopPropagation(),
      },
      hover: {
        animationDuration: 1,
      },
      elements: {
            line: {
              tension: 0, // Suavizar las curvas de las líneas
             // borderWidth: 30,
              backgroundColor: '#trasmparent', // Fondo de la línea transparente
            },
            point: {
              radius: 4,
              hitRadius: 8,
              hoverRadius: 6,
              backgroundColor: 'trasmparent', // Puntos sin color de fondo
            },
          },
      },
    };
  },
  computed: {
    ...mapState('results', {
      resultsLoading: 'loading',
    }),
    loading() { return !!this.resultsLoading; },
    generalPanelLink() {
      return { name: 'coexistenceGeneralPanelsSchoolCoexistence', params: this.$route.params };
    },
     coexistenceGPSchoolEvolucionLink() {
      return { name: 'coexistenceGPSchoolEvolucion', params: this.$route.params };
    },
     coexistenceGPSchoolComparisonLink(){
      return { name: 'coexistenceGPSchoolComparacion', params: this.$route.params };
    },
    coexistenceGPSchoolDistribucionLink() {
       return { name: 'coexistenceGPSchoolDistribucion', params: this.$route.params };
    },

      bySchoolTabs() {
      return [
        { icon: '', name: 'General', link: this.generalPanelLink },
        { icon: '', name: 'Evolución mensual', link: this.coexistenceGPSchoolEvolucionLink },
        { icon: '', name: 'Comparación', link: this.coexistenceGPSchoolComparisonLink },
        { icon: '', name: 'Distribución por fecha', link: this.coexistenceGPSchoolDistribucionLink },
      ];
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true, 'months': true });
      this.setReportKey('behavior');
    },

    toggleDatasetVisibility(index, showAll = false, nameTab) {
      debugger;
     
      if (index === null) {
        // Si se hace clic en "Todos", desactivar cualquier selección individual
        this.activeTab = null;
      } else {
        // Si se selecciona un botón específico, asignar su índice como activeTab
        // this.activeTab = this.activeTab === index ? null : index; // Alternar selección         
        this.activeTab = nameTab;        
      }
      const chart = this.$refs.lineGraph.chartInstance;  

          // Si el parámetro showAll es true, mostrar todos los datasets
          if (showAll) {
              chart.data.datasets.forEach(dataset => {
                  dataset.hidden = false;  // Mostrar todos los datasets
              });
              chart.update();
              return;  // Salir de la función después de mostrar todos los datasets
          }

          // Si index no es null, proceder a mostrar/ocultar datasets según los índices
          chart.data.datasets.forEach((dataset, i) => {
              // Si el índice está en el array de índices, mostrarlo, de lo contrario, ocultarlo
              dataset.hidden = !index.includes(i);
          });

          // Actualizar el gráfico para reflejar los cambios
          chart.update();
      }
  },
};
</script>
<style>
  /*@import "../../../styles/app/variables";*/
.content-filters {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

  div#content-attendance-e {
      padding: 15px;
      border-radius: 8px;
  }

  .active_legen {
   
}

.contentlegen {
    display: flex;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    margin-right: 15px;
    gap: 5px;
    font-size: 14px;
}

.contenGraficas-line-evolution .legen {
      height: 16px;
      width: 16px;
      border-radius: 50%;
  }
  .contenGraficas-line-evolution .legen.all {
      background: grey;
  }

  .contenGraficas-line-evolution .legen.positivos {
      background: #77BD25;
  }

  .contenGraficas-line-evolution .legen.negativos {
      background: #FF5454;
  }

  .contenGraficas-line-evolution .legen.neutros {
      background: #FFCF54;
  }
  
  .conten-filters {
    height: 60px;
  }

  .content-result {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .panel-content-result-e {
      display: flex;
      border-bottom: 1px solid #efeff5;
      box-shadow: 0px 4px 16px rgba(21, 84, 156, .08);
      flex-direction: column;
      align-items: center;
      justify-content:center;
      width: 20%;
      border-radius: 8px;
      background: #EDF4FD;
    }

  .panel-content-result-e .ttl-panel {
    font-size: 18px;
    color: #505068;
    font-weight: 600;
    text-align: center;
  }

  .panel-content-result-e .result-panel {
     font-size: 34px;
    color: #237CE1;
    line-height: 2;
    font-weight: 500;
  }

  .content-justify-linechart {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .content-justify-linechart .wrapper-graph-line canvas#line-chart {
    max-height: 350px;
    min-height: 350px;
  }
</style>
