<template>
  <div>
    <rdr-results-header :show-school-selector="isLoggedIn" year-picker group-picker show-school-picker
      show-school-year-picker only-result-groups>
    </rdr-results-header>
    <tabs :tabs="bySchoolTabs" :tab-width="200" links />
    <div id="content-attendance-g" class="results-colormap">
      <div class="content-result-g">
        <div class="panel-content-result-g">
          <div class="ttl-panel">Total de registros del curso</div>
          <div class="result-panel">{{ this.total }}</div>
        </div>
        <div class="panel-content-result-g">
          <div class="ttl-panel">Distribución de los registros</div>
          <doughnut-hart :chartData="dataset1"></doughnut-hart>
        </div>
      </div>
      <div v-if="loadGraph" class="contenTable-attendance">
        <div v-if="loading">
          <transition name="fade">
            <loading-overlay />
          </transition>
        </div>
        <div v-else>
          <template v-if="loadGraph">
            <div :class="$style.table">
              <div class="contenScroll">
                <rdr-table 
                :columns="columns" 
                :data="rows"
                :click="handleClick" 
                :custom-class="$style.courseReportTable"
                :extended-header-class="$style.courseReportHeaderCell" 
                :extended-cell-class="$style.courseReportCell" 
                show-row-index/>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import ResultsHeader from '../../../components/results/results-attendance-header.vue';
import Tabs from '../../../components/tabs.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';
// import barGraph from '../../../components/bar-graph-group.vue';
import doughnutChart from '../../../components/doughnut-chart.vue';

/* eslint-disable camelcase */

export default {
  name: 'CoexistencePanelBySchoolCourseView',
  components: {
    //ColorMapFooter,
    'rdr-results-header': ResultsHeader,
    //barGraph,
    'doughnut-hart': doughnutChart,
    'tabs': Tabs,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      loadGraph: true,
      activeTab: null,
      txtVert: 'Valores del Dataset',
      doughnutOptions: {
        circumference: 180,
        rotation: -90,
      },
      optionsRows: [10, 20, 30, 40],
      pageSize: 10,
      showPaginator: true,
    };
  },
  computed: {
    ...mapState('results', {
      resultsLoading: 'loading',
    }),
    ...mapGetters('results', {
      resultsGetter: 'results',
    }),
    loading() { return !!this.resultsLoading; },
    coexistenceBySchoolCourseLink() {
      return { name: 'coexistenceBySchoolCourse', params: this.$route.params };
    },
    coexistenceGPCurseEvolucionLink() {
      return { name: 'coexistenceGPCurseEvolucion', params: this.$route.params };
    },
    coexistenceGPCurseComparisonLink(){
      return { name: 'coexistenceGPCurseComparacion', params: this.$route.params };
    },
    coexistenceGPCurseDistribucionLink() {
      return { name: 'coexistenceGPCurseDistribucion', params: this.$route.params };
    },
    // coexistenceGPCurseAsignaturaLink() {
    //   return { name: 'coexistenceGPCurseAsignatura', params: this.$route.params };
    // },
      bySchoolTabs() {
      return [
        { icon: '', name: 'General', link: this.coexistenceBySchoolCourseLink },
        { icon: '', name: 'Evolución mensual', link: this.coexistenceGPCurseEvolucionLink },
        { icon: '', name: 'Comparación', link: this.coexistenceGPCurseComparisonLink },
        { icon: '', name: 'Distribución por fecha', link: this.coexistenceGPCurseDistribucionLink },
        // { icon: '', name: 'Por asignatura', link: this.coexistenceGPCurseAsignaturaLink },
      ];
    },

    columns() {
      const tableColumns = [
        { key: 'nombre', label: 'Nombre', textAlign: 'left'},
        { key: 'positivos', label: 'Positivos' },
        { key: 'neutros', label: 'Neutros' },
        { key: 'negativos', label: 'Negativos',},
        //...this.monthsColumns,
      ];

      return tableColumns;
    },

    rows() {
      if(this.resultsGetter.length === 0) 
        return [];
      else {
        return this.resultsGetter[0].rows.slice();
      }
    },

    dataset1() {
      if(this.resultsGetter.length === 0) 
        return {
          labels: [],
          datasets: []
        }
      else {
        return this.resultsGetter[0].dataset1;
      }
    },

    total() {
      if(this.resultsGetter.length === 0) 
        return 0;
      else {
        return this.resultsGetter[0].total;
      }
    },

  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.optionsForChosenSchoolOnly = true;
      this.setReportParams({ 'school_ids': true, 'years': true, 'group_ids': true});
      this.setReportKey('behavior-general-group');
    },

    handleClick({ id }) {
      localStorage.setItem('url_student_report', window.location.hash);
      this.$router.push({
        name: 'studentReport',
        params: { student: id },
      });
    },
  },
};
</script>
<style lang="scss" module>
@import "../../../../styles/app/variables";

  table.course-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $sidebar-background;
      }
    }
    tr:hover {
      td {
        background-color: #f9f9fd;
      }
    }
  }
}

div.course-report-header-cell {
  height: 42px;

  span {
    flex: 1;
  }
}

div.course-report-cell {
  font-size: 14px;
  height: 40px;
}


</style>
