<template>
  <div class="report">
    <div
      v-if="loading || error"
      class="rdr-view">
      <transition name="fade">
        <loading-overlay v-if="loading"/>
      </transition>
      <go-back-link arrow/>
      <div v-if="error">
        <strong>Ocurrió un error. Intenta de nuevo.</strong>
      </div>
    </div>
    <div
      v-else
      class="rdr-view">
      <div :class="['report__options', 'hide-print', $style.stickyButtons]">
        <go-back-link arrow url-storage/>
        <print-button
          :loading="!!loading"
          :class="$style.printButton"
          primary/>
      </div>
      <div :class="$style.informationWrapper">
        <div
          :class="[$style.row, $style.headerRow]"
        >
          <img
            v-if="student.school_logo"
            :class="$style.logoImage"
            :src="student.school_logo"
            alt="Thumbnail">
          <div :class="$style.bigText">
            <h1
              v-if="schoolName"
              class="info__school-name">
              <strong>{{ schoolName | startCase }}</strong>
            </h1>
            <h2 :class="$style.infoName">
              <strong>{{ student.full_name }}</strong>
              <strong> - Ficha {{ year }} </strong>
              <em
                v-if="retired"
                class="info__retired">
                (Retirado/a{{ unenrollmentDate ? ` el ${unenrollmentDate}` : '' }})
              </em>
            </h2>
          </div>
        </div>
        <br>
        <div :class="[$style.row, $style.inverseMobileRow]">
          <div :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">RUN:</span>
            <span>
              {{ student.run | number }}-{{ student.run_dv }}
            </span>
          </div>
          <div :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Curso en {{ student.year }}:</span>
            <span>{{ student.group_name }}</span>
          </div>
          <div
            v-if="studentGender"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Género:</span>
            <span>{{ studentGender }}</span>
          </div>
          <div
            v-if="showCommune"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Comuna de residencia:</span>
            <span>{{ student.commune | startCase }}</span>
          </div>
          <div
            v-if="student && student.email"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Correo:</span>
            <span>{{ student.email }}</span>
          </div>
          <div
            v-if="student && student.phone"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Teléfono:</span>
            <span>{{ student.phone }}</span>
          </div>
          <div
            v-if="student && student.cellphone"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Celular:</span>
            <span>{{ student.cellphone }}</span>
          </div>
          <div
            v-if="student && student.birthdate"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Fecha de nacimiento:</span>
            <span>{{ student.birthdate }}</span>
          </div>
          <div
            v-if="student.first_enrollment_entry"
            :class="$style.detailedInfo">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">Año ingreso estimado:</span>
            <span>{{ student.first_enrollment_entry.year }}</span>
          </div>
          <div
            v-if="showRepetitionRisk"
            :class="$style.detailedInfo">
            <i
              v-if="student.repetition_risk === null"
              :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <i
              v-else
              :class="['material-icons', $style.riskLabel]"> label_important </i>
            <span class="info__label">{{ repetitionRiskLabel }}:</span>
            <span>{{ repetitionRisk }}</span>
          </div>
          <div
            v-for="result in psicometrixResults"
            :class="$style.detailedInfo"
            :key="result.year">
            <i :class="['material-icons', $style.dot]"> fiber_manual_record </i>
            <span class="info__label">{{ psicometrixLabel(result.testType) }}:</span>
            <span>{{ result.description }} (compatibilidad {{ result.score }}% en test Psicometrix {{ result.year }})</span>
          </div>
          <div
            v-if="student.pie_boolean"
            :class="$style.detailedInfo">
            <i
              :class="['material-icons', $style.riskLabel]"> label_important </i>
            <span class="info__label">PIE:</span>
            <rdr-tooltip
              :disabled="!student.pie_boolean"
              class="info__value info__pie">
              <div v-html="student.pie_name"/>
              <span slot="reference">{{ pie }}</span>
            </rdr-tooltip>
          </div>
          <div
            v-if="priority"
            :class="$style.detailedInfo">
            <i
              :class="['material-icons', $style.riskLabel]"> label_important </i>
            <span class="info__label">Estudiante Prioritario</span>
          </div>
          <div
            v-if="preferential"
            :class="$style.detailedInfo">
            <i
              :class="['material-icons', $style.riskLabel]"> label_important </i>
            <span class="info__label">Estudiante preferente.</span>
          </div>
          <div
            v-for="repetition in historicalRepetitionData"
            :class="$style.detailedInfo"
            :key="repetition.year">
            <i
              :class="['material-icons', $style.riskLabel]"> label_important </i>
            <span class="info__label">Repitió </span>
            <span> {{ repetition.group_short_name }} en {{ repetition.year }}</span>
          </div>
        </div>
      </div>
      <tabs
        v-model="currentTab"
        :tabs="tabs"/>
      <summary-view
        v-if="currentTab === 'summary'"
        :attendances="student.yearly_attendances"
        :grades="student.yearly_grades"
        :data-report="dataReport"
        :final-grades="student.yearly_final_grades"
        :years="evolutionYears"
        :v-axis-format="attendanceChartFormat"
        :year="year"
        :has-aptus-results="hasAptusResults"
        :aptus-results="student.averaged_aptus_results"
        :reading-quality-results="student.reading_quality_results"
        :aptus-color-picker="aptusColorPicker"
        :survey-alerts="student.survey_alerts"
        :pulso-alerts="student.pulso_alerts"
        :show-aptus-explanation="showAptusExplanation"
        :aptus-explanation-message="aptusExplanationMessage"
        :show-reading-quality-explanation="showReadingQualityExplanation"
        :reading-quality-explanation-message="readingQualityExplanationMessage"
      />
      <attendance-view
        v-else-if="currentTab === 'attendance'"
        :v-axis-format="attendanceChartFormat"
        :year="year"
        :chart-attendance="student.monthly_attendances"
      />
      <grades-view
        v-else-if="currentTab === 'grades'"
        :year="year"
        :grades="student.yearly_grades"
        :years="evolutionYears"/>
      <!-- <aptus-view
        v-else-if="currentTab === 'aptus'"
        :results="student.aptus_results"
        :subject-map="student.aptus_subject_map"
        :color-picker="aptusColorPicker"
        :aptus-explanation-message="aptusExplanationMessage"
        :show-aptus-explanation="showAptusExplanation"
      />-->
    </div>
  </div>
</template>

<script>
import StudentsApi from '../../api/students';
import PermissionMixin from '../../mixins/permission';
import MockDataMixin from '../../mixins/mock-data';
import Decree67Mixin from '../../mixins/decree-67';
import GoBackLink from '../../components/go-back-link.vue';
import ColumnChart from '../../components/column-chart.vue';
import BarChart from '../../components/bar-chart.vue';
import Tabs from '../../components/tabs.vue';
import PrintButton from '../../components/utils/print-button.vue';
import GRAPH_COLORS from '../../utils/graph-colors';
import AttendanceView from './attendance.vue';
import SummaryView from './summary.vue';
import GradesView from './grades.vue';
import AptusView from './aptus.vue';
import { repetitionRiskValueToMessage } from '../../utils/repetition-risk';
import { SEMAPHORE_COLORS } from '../../utils/style-variables';
import { longGenderMap } from '../../utils/gender-mapping';

export default {
  name: 'StudentReportView',
  components: {
    GoBackLink,
    ColumnChart,
    Tabs,
    AttendanceView,
    SummaryView,
    GradesView,
    AptusView,
    BarChart,
    PrintButton,
  },
  mixins: [PermissionMixin, MockDataMixin, Decree67Mixin],
  data() {
    return {
      loading: 0,
      error: false,
      student: null,
      historical: null,
      attendanceChartFormat: '#\'%\'',
      attendanceChartColors: GRAPH_COLORS,
      currentTab: 'summary',
      quoteHeader: '¿Quieres visualizar las calificaciones y recibir alertas de posible repitencia?',
      aptusExplanationMessage: 'Importante: El logro normalizado de las evaluaciones 2020 de Aptus se realizó con una referencia de colegios distinta a SIP red de colegios.',
      readingQualityExplanationMessage: 'Importante para considerar en el análisis: Dadas las circunstancias del año 2020, la aplicación de esta evaluación se hizo de manera remota.',
    };
  },
  computed: {
    showAptusExplanation() {
      // eslint-disable-next-line no-magic-numbers
      return Object.values(this.student.aptus_results).some(o => o.group.some(r => r.year === 2020) || o.student.some(r => r.year === 2020));
    },
    showReadingQualityExplanation() {
      // eslint-disable-next-line no-magic-numbers
      return this.student.reading_quality_results.some(o => o.year === 2020);
    },
    quoteText() {
      if (this.isAptusAccount && !this.hasGradesPermission) {
        return 'Actualmente tienes el plan Asistencia en alianza con Aptus. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que, además de la asistencia y evaluaciones Aptus, te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante)';
      } else if (!this.hasGradesPermission) {
        return 'Actualmente tienes un plan que no permite hacer seguimiento de calificaciones. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante).';
      }
      return '';
    },
    tabs() {
      return [
        { key: 'summary', text: 'Resumen', icon: '' },
        { key: 'attendance', text: 'Asistencia', icon: '' },
        { key: 'grades', text: 'Calificaciones', icon: '' },
        // ...(this.showAptusTab ? [{ key: 'aptus', text: 'Evaluaciones Aptus', icon: '' }] : []),
      ];
    },
    studentId() {
      return this.$route.params.student;
    },
    retired() {
      return this.student && this.student.retired;
    },
    unenrollmentDate() {
      return this.student && this.student.unenrollment_date;
    },
    priority() {
      return this.student && this.student.priority;
    },
    preferential() {
      return this.student && this.student.preferential;
    },
    pie() {
      if (this.student && this.student.pie_boolean) {
        return this.student.pie_type;
      }
      return 'No';
    },
    showAptusTab() {
      return this.hasAptusResults || this.isAptusAccount;
    },
    psicometrixResults() {
      return this.student.historical_latest_psicometrix_results
        .map(r => ({
          testType: r.test_type,
          description: r.description,
          score: r.score,
          year: r.year,
        }));
    },
    hasAptusResults() {
      return Object.keys(this.student.aptus_results).length !== 0;
    },
    showRepetitionRisk() {
      return this.student.repetition === null && this.student.repetition_risk;
    },
    showCommune() {
      return this.student.commune !== null;
    },
    repetition() {
      return this.student && this.student.repetition;
    },
    repetitionRisk() {
      return repetitionRiskValueToMessage(this.student.repetition_risk);
    },
    year() {
      return this.student && this.student.year;
    },
    evolutionYears() {
      return [...new Set(this.student.yearly_attendances.map(a => a.year).concat(this.student.yearly_grades.map(g => g.year)))].sort();
    },
    schoolName() {
      return this.student && this.student.school_name;
    },
    studentGender() {
      return longGenderMap(this.student.gender);
    },
    historicalRepetitionData() {
      return this.student && this.student.historical_repetition_data;
    },
    aptusColorPicker() {
      return value => {
        if (value > 0) {
          return SEMAPHORE_COLORS.high;
        } else if (value < -1) {
          return SEMAPHORE_COLORS.low;
        }
        return SEMAPHORE_COLORS.middle;
      };
    },
    dataReport() {
      return {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
               {
                  label: 'Negativos',
                  fill: false,
                  backgroundColor: '#FF5454', // Rojo
                  borderColor: '#FF5454', // Rojo
                  borderWidth: 3,
                  data: [70, 65, 68, 70, 70, 70, 65, 70, 75, 65, 70, 80],
                  stack: 'Stack 0',
              },
              {
                  label: 'Neutros',
                  fill: false,
                  backgroundColor: '#FFCF54', // Naranja
                  borderColor: '#FFCF54', // Naranja
                  borderWidth: 3,
                  data: [28, 35, 40, 25, 20, 28, 48, 40, 40, 20,10,40],
                  stack: 'Stack 0',
              },
              {
                  label: 'Positivos',
                  fill: false,
                  backgroundColor: '#77BD25', // Verde
                  borderColor: '#77BD25', // Verde
                  borderWidth: 3,
                  data: [60, 50, 55, 50, 40, 50, 55, 60, 70, 40,40,45],
                  stack: 'Stack 0',
              },
          ]
      }
    },
  },
  watch: {
    '$route': {
      async handler() {
        await this.fetchStudent();
      },
      immediate: true,
    },
  },
  methods: {
    psicometrixLabel(testType) {
      switch (testType) {
      case 'pv':
        return 'Área profesional afín';
      case 'ov':
        return 'Carrera de interés';
      default:
        return null;
      }
    },
    fetchStudent() {
      this.loading++;
      StudentsApi.getStudent(this.studentId)
        .then((response) => {
          this.student = response.body.evaluable_student_show;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading--;
        });
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.logo-image {
  flex: 0 1 auto;
  height: 110px;
}

.print-button {
  align-self: flex-end;
}

.information-wrapper{
  padding: 0 20px;
}

.attendance-chart-block {
  @media only print {
    transform: translateX(-50px) scale(0.9);
  }
}

.info-name {
  font-weight: 100;
  font-size: 28px;
  text-align: center;
}

.big-text {
  flex: 2;
}

.dot {
  font-size: 10px !important;
  color: #6483FB;
}

.warning-label {
  font-size: 10px !important;
  color: #DF6666;
}

.risk-label {
  color: #E6837A;
  font-size: 12px !important;
}

.grades-chart-block {
  @media only print {
    transform: translateX(-25px) scale(0.9);
  }
}

.column {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  div {
    margin-bottom: 15px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }
}

.inverse-mobile-row {
  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
}

.header-row {
  align-items: center;
}

.detailed-info {
  width: 40%;
  margin-bottom: 5px;
}

.sticky-buttons {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 3px 0;
  z-index: 1;
  border-bottom: 1px solid #eee;
}
</style>
